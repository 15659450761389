import React from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { useQuery } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { Credits } from '../../components/ui/Credits';
import { Stepper } from './Stepper';
import { useBreakpoint } from '../../hooks/useBreakPoint';
import { additionalContactInfoRequired } from '../auth/utils';

import { WizardForm } from './model';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { Step5 } from './Step5';
import { useAuthUser } from '../auth/useAuthUser';
import { useSiteWizard } from './useSiteWizard';
import { CAN_CREATE_SOIL_SITE } from './graphql';
import { Loader } from '../../components/ui';

export const SiteWizard: React.FC = () => {
  const authUser = useAuthUser();

  const { data, loading } = useQuery(CAN_CREATE_SOIL_SITE, {
    fetchPolicy: 'network-only',
  });

  const {
    pageRef,
    steps,
    currentStep,
    goToStep,
    handleMoveStep,
    handleSubmit,
    validateForm,
    getInitialValues,
  } = useSiteWizard();

  const showStep4 =
    additionalContactInfoRequired(authUser, 'create') || !authUser.firstName;

  if (!data && loading) return <Loader />;

  if (!data.canCreateSoilSite) return <Redirect to="/pro/multi-inquiry" />;

  return (
    <AnimatePresence>
      <Container>
        <Content>
          <FormContainer>
            <Formik<WizardForm>
              initialValues={{
                ...getInitialValues(authUser.id),
                firstName: authUser.firstName || '',
                lastName: authUser.lastName || '',
                phoneNum: authUser.phoneNum || '',
                twitter: authUser.twitter || '',
                instagram: authUser.instagram || '',
                facebook: authUser.facebook || '',
                linkedin: authUser.linkedin || '',
              }}
              validate={validateForm}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values, isValid }) => {
                return (
                  <Form>
                    <Stepper
                      currentStep={currentStep}
                      ref={pageRef}
                      steps={steps.current.length}
                      canAdvance={isValid && !isSubmitting}
                      onPrevStep={() => handleMoveStep(values, 'prev')}
                      onNextStep={() => handleMoveStep(values, 'next')}
                    >
                      <Step1 stepCount={steps.current.length} />
                      <Step2 stepCount={steps.current.length} />
                      <Step3
                        stepCount={steps.current.length}
                        goToStep={goToStep}
                      />
                      {showStep4 && <Step4 stepCount={steps.current.length} />}
                      <Step5
                        stepCount={steps.current.length}
                        currentStep={currentStep}
                      />
                    </Stepper>
                  </Form>
                );
              }}
            </Formik>
          </FormContainer>
        </Content>
        <WizardImage image={steps.current[currentStep].image} />
      </Container>
    </AnimatePresence>
  );
};

const WizardImage = ({ image }) => {
  const breakpoint = useBreakpoint();
  if (breakpoint === 'sm') return null;

  return (
    <CoverImage
      key={image.url}
      variants={{
        enter: {
          opacity: 0,
        },
        center: {
          zIndex: 1,
          opacity: 1,
        },
        exit: {
          zIndex: 0,
          opacity: 0,
        },
      }}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        opacity: { duration: 2 },
      }}
      image={image.url}
    >
      {image.caption && (
        <Credits align="right">
          <span dangerouslySetInnerHTML={{ __html: image.caption }} />
        </Credits>
      )}
    </CoverImage>
  );
};

//#region styled components

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #242424;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: row;
  }
`;

const Content = styled.div`
  height: 100%;
  width: 100%;

  flex: 3;
  order: 1;

  position: relative;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.contentBackground};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 300px;
    height: 100%;
    flex: 2;
    order: 0;
  }
`;

const FormContainer = styled.div`
  height: 100%;

  form {
    height: 100%;
  }

  .step-title {
    font-size: 1.875rem;
    font-weight: 400;
  }

  hr {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }

  h3 {
    margin-top: 2.5rem;
    font-weight: 500;
  }

  input {
    font-size: 1rem;
    font-weight: 400;
    color: #495057;
  }
`;

const CoverImage = styled(motion.div)<{
  image: string;
  size?: string;
  position?: string;
}>`
  background: url(${({ image }) => image}) no-repeat;
  background-size: ${({ size }) => size || 'cover'};
  background-position: ${({ position }) => position || 'center center'};
  position: relative;
  height: 225px;
  min-height: 225px;
  width: 100%;

  flex: 1;

  @media only screen and (max-device-width: 1024px) and (orientation: landscape) {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex: 3;
    order: 1;
    height: 100%;
  }

  .caption {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.75);

    span {
      color: #fff;
      max-width: 50%;
      font-size: 0.9rem;
      font-style: italic;
      text-align: right;
    }
  }
`;

//#endregion
