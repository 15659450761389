import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';

import authReducer, { emptyUser } from '../features/auth/authSlice';
import userProfileReducer from '../features/userProfile/userProfileSlice';
import notificationCentreReducer from '../features/notifications/notificationCentreSlice';
import SiteWizardReducer from '../features/siteWizard/siteWizardSlice';
import mapReducer from '../features/map/mapSlice';
import messengerReducer from '../features/messenger/messengerSlice';
import layoutReducer from '../features/layout/layoutSlice';
import versionReducer from '../features/version/versionSlice';
import locationPickerReducer from '../features/locationPicker/locationPickerSlice';

import messageBoardReducer from '../features/site/messageboard/messageBoardSlice';
import soilSiteReducer from '../features/site/soilSiteSlice';

import userManagementReducer from '../features/admin/userManagement/userManagementSlice';
import featureManagementReducer from '../features/admin/userManagement/premium/featureManagementSlice';

import siteManagementReducer from '../features/admin/siteManagement/siteManagementSlice';

import { toaster } from '../features/notifications/toastMiddleware';

const adminReducer = combineReducers({
  userManagement: userManagementReducer,
  featureManagement: featureManagementReducer,
  siteManagement: siteManagementReducer,
});

const siteReducer = combineReducers({
  management: soilSiteReducer,
  messageBoard: messageBoardReducer,
});

const rootReducer = combineReducers({
  auth: authReducer,
  notificationCentre: notificationCentreReducer,
  map: mapReducer,
  messenger: messengerReducer,
  userProfile: userProfileReducer,
  siteWizard: SiteWizardReducer,

  site: siteReducer,

  locationPicker: locationPickerReducer,

  layout: layoutReducer,
  version: versionReducer,

  admin: adminReducer,
});

const reducerProxy = (state: RootState, action: AnyAction) => {
  if (action.type === 'logout') {
    // reset the store
    return rootReducer(
      {
        ...state,
        admin: undefined,
        messenger: undefined,
        notificationCentre: undefined,
        userProfile: undefined,
        locationPicker: undefined,
        siteWizard: undefined,
        auth: {
          ...state.auth,
          user: emptyUser,
        },
      },
      action
    );
  }
  return rootReducer(state, action);
};

export const store = configureStore({
  reducer: reducerProxy,
  devTools: process.env.REACT_APP_BRANCH !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(toaster),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
