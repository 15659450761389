import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { theme } from '../../../../app/theme';
import { Loader, Grid } from '../../../../components/ui';
import { Icon } from '../components/Components';
import { InsightBox } from '../components/InsightBox';
import { MapAndGraph } from './MapAndGraph';

const GET_PARTICIPATION = gql`
  query ParticipationInRegion($region: RegionInput!) {
    participationInRegion(region: $region) {
      activeSoilSites {
        id
        location {
          lat
          lng
        }
      }
      householdsParticipating
    }
  }
`;

type Props = {
  region: {
    id: number;
    type: string;
    keyword: string;
    location: any;
    viewport: any;
    country: string;
  };
};

export function Participation({
  region: { id, type, keyword, location, viewport, country },
}: Props) {
  const { loading, data } = useQuery(GET_PARTICIPATION, {
    variables: {
      region: {
        id,
        type,
        keyword,
        country,
      },
    },
  });

  if (loading) return <Loader />;

  const {
    activeSoilSites,
    householdsParticipating,
  } = data.participationInRegion;

  return (
    <Container>
      <Header>
        <Icon>
          <FontAwesomeIcon icon="users" />
        </Icon>
        <strong>PARTICIPATION</strong>
      </Header>
      <Content>
        <Grid col="repeat(2, 1fr)" gap="1rem">
          <InsightBox
            title="Soil Sites Activated"
            value={activeSoilSites.length}
            icon="leaf"
            color={theme.colors.primary}
          />
          <InsightBox
            title="Households Participating"
            value={householdsParticipating}
            icon="home"
            color={theme.colors.primary}
          />
        </Grid>
      </Content>

      <MapAndGraph
        region={{ id, type, keyword, location, viewport, country }}
        activeSoilSites={activeSoilSites}
      />
    </Container>
  );
}

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  padding: 0.75rem 1rem;

  border-bottom: 1px solid #d7d7d7;
`;

const Container = styled.div`
  background-color: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
`;

const Content = styled.div`
  padding: 1rem;
`;
