import { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';

import { Avatar, Flex, Loader } from '../../../components/ui';
import PostInput from './PostInput';
import { Post } from './Post';
import { PostInputData, SitePost } from './model';
import { useSoilSiteContext } from '../SoilSiteContext';
import { useAuthUser } from '../../auth/useAuthUser';
import { useMessageBoardSubscription } from './useMessageBoardSubscription';
import { useMessageBoard } from './useMessageBoard';
import { SiteImpact } from '../impact/Impact';
import { useAdaptiveLayout } from '../hooks';

type Props = {
  onRegisterDropOff: () => void;
  onAddSupporters: () => void;
  onEditDropOff: (post: SitePost) => void;
};

export const MessageBoard = ({
  onRegisterDropOff,
  onEditDropOff,
  onAddSupporters,
}: Props) => {
  const authUser = useAuthUser();
  const { soilSite } = useSoilSiteContext();
  const siteView = useAdaptiveLayout();

  const {
    posts,
    loading,
    fetchPosts,
    fetchMore,
    addSitePost,
    cleanupMessageBoard,
  } = useMessageBoard();

  useEffect(() => {
    fetchPosts(soilSite.id);
  }, [fetchPosts, soilSite.id]);

  useEffect(() => {
    return () => cleanupMessageBoard();
  }, [cleanupMessageBoard]);

  useMessageBoardSubscription(soilSite.id);

  const handleCreatePost = async (postData: PostInputData) => {
    addSitePost({ postData, siteId: soilSite.id });
  };

  const fetchMoreIfScrolledToBottom = (e) => {
    const fetchAmount = 20;

    const { scrollHeight, scrollTop, clientHeight } = e.target;
    const scrolledToBottom = scrollHeight - scrollTop - clientHeight === 0;
    if (scrolledToBottom && posts.length >= fetchAmount) {
      const cursor = posts[posts.length - 1].createdAt;
      fetchMore({ siteId: soilSite.id, cursor });
    }
  };

  // separate pinned threads
  // can we do this better???
  const pinnedPosts = posts.filter((t) => t.pinned);
  const filteredPosts = posts.filter((m) => !m.pinned);
  const hasPinnedPosts = pinnedPosts.length > 0;

  const showImpact = siteView === 'grid';

  return (
    <ActivityBoardContainer onScroll={fetchMoreIfScrolledToBottom}>
      <ActivityBoardHeader>
        {showImpact && (
          <SiteImpact
            onRegisterDropOff={onRegisterDropOff}
            onAddSupporters={onAddSupporters}
          />
        )}

        <p>
          Activity at <strong>{soilSite.name}</strong>
        </p>

        <PostInputContainer>
          <Avatar user={authUser} size="sm" />
          <PostInput
            style={{ marginLeft: '0.5rem' }}
            users={soilSite.members}
            onPostMessage={handleCreatePost}
          />
        </PostInputContainer>
      </ActivityBoardHeader>

      {hasPinnedPosts && (
        <PostsContainer pinned>
          <h5>
            <FontAwesomeIcon color="#616161" icon="thumbtack" /> Pinned Posts
          </h5>
          <Posts
            posts={pinnedPosts}
            participants={soilSite.members}
            onEditDropOff={onEditDropOff}
          />
        </PostsContainer>
      )}

      {loading ? (
        <Loader />
      ) : (
        <Posts
          posts={filteredPosts}
          participants={soilSite.members}
          onEditDropOff={onEditDropOff}
        />
      )}
    </ActivityBoardContainer>
  );
};

const Posts = ({ posts, participants, onEditDropOff }) => {
  const variants = {
    enter: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      x: -30,
    },
  };

  return (
    <PostsContainer>
      <AnimatePresence>
        <Flex direction="column" gap="0.5rem">
          {posts.map((post) => (
            <motion.div
              key={post.id}
              initial="exit"
              animate="enter"
              exit="exit"
              variants={variants}
            >
              <Post
                post={{ ...post }}
                participants={participants}
                onEditDropOff={onEditDropOff}
              />
            </motion.div>
          ))}
        </Flex>
      </AnimatePresence>
    </PostsContainer>
  );
};

export const ActivityBoardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #efefef;
  border-radius: 0 0 4px 4px;
  border-top: none;

  height: 100%;
  overflow-y: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-bottom: 40px;
  }
`;

export const ActivityBoardHeader = styled.div`
  padding: 1rem;
  font-size: 0.925rem;

  h3,
  p {
    margin: 0;
    font-weight: 400;

    strong {
      font-weight: 600;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      margin-top: 1rem;
      font-size: 1rem;
    }
  }
`;

export const PostInputContainer = styled.div`
  display: flex;
  background: #fff;

  margin-top: 0.5rem;
  padding: 0.75rem;
  
  border-radius: 4px;
  border-1px solid #d7d7d7;
  box-shadow: 0 0 3px rgba(91, 91, 91, 0.3);
`;

export const PostsContainer = styled.div<{ pinned?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem;
  position: relative;

  ${(props) =>
    props.pinned &&
    css`
      padding-top: 1rem;
      h5 {
        margin: 0;
      }
      background-color: #e7f1e4;
    `}
`;

export const NoPosts = styled.div`
  text-align: center;
  padding: 0 1rem 2rem;

  h3 {
    text-align: center;
    padding: 0 1rem;
    margin: 1rem 0 0;
  }
`;
