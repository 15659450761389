import { useState } from 'react';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import { Redirect, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RegionSelector } from './components/RegionSelector';
import { Button, DropMenu, Flex, Loader } from '../../../components/ui';
import { Meta } from '../../../components/Meta';
import { ILocation, ViewportBounds } from '../../../app/model/ILocation';
import { Icon } from './components/Components';
import { ActivateModal, ActivateModalTab } from './components/ActivateModal';
import { Participation } from './participation/Participation';
import { Impact } from './impact/Impact';

const CAN_USE_INSIGHTS = gql`
  query CanUseInsights {
    canUseInsights {
      enabled
      regions {
        id
        name
        keyword
        type
        country
        location {
          lat
          lng
        }
        viewport {
          south
          west
          north
          east
        }
      }
    }
  }
`;

type CanUseInsightsResponse = {
  canUseInsights: {
    enabled: boolean;
    regions: {
      id: number;
      name: string;
      keyword: string;
      type: string;
      country: string;
      location: ILocation;
      viewport: ViewportBounds;
    }[];
  };
};

const Insights = () => {
  const history = useHistory();
  const [regionId, setRegionId] = useState();

  const [modalTab, setModalTab] = useState<ActivateModalTab>();

  const { data, loading } = useQuery<CanUseInsightsResponse>(CAN_USE_INSIGHTS);

  if (!data && loading) return <Loader />;

  const { enabled, regions } = data.canUseInsights;
  if (!enabled) return <Redirect to="/activate" />;

  const selectedRegion = regions.find((r) => r.id === regionId) || regions[0];

  const handleActivate = (tab: ActivateModalTab) => {
    setModalTab(tab);
  };

  return (
    <>
      <Meta>
        <title>Activate | MakeSoil</title>
      </Meta>

      {modalTab && (
        <ActivateModal
          tab={modalTab}
          isOpen={!!modalTab}
          onClose={() => setModalTab(null)}
        />
      )}

      <PageContainer>
        <PageHeader>
          <Flex align="center" gap="0.5rem">
            <Icon>
              <FontAwesomeIcon icon="chart-line" />
            </Icon>
            <HideMobile>
              <strong style={{ fontSize: '1.125rem' }}>ACTIVATE</strong>
            </HideMobile>
            <div />
            <RegionSelector
              regions={regions}
              onSelected={setRegionId}
              defaultRegionId={selectedRegion.id}
            />
          </Flex>
          <Flex>
            <DropMenu
              triggerElement={
                <Button padding="0.5rem 0.75rem">
                  <FontAwesomeIcon icon="user-plus" /> Activate
                </Button>
              }
            >
              <span onClick={() => handleActivate('qr')}>
                <FontAwesomeIcon icon="qrcode" /> Use the QR code
              </span>
              <span onClick={() => handleActivate('link')}>
                <FontAwesomeIcon icon="share" /> Share the link
              </span>
              <span onClick={() => handleActivate('resources')}>
                <FontAwesomeIcon icon="trash" /> Offer resources
              </span>
              <span onClick={() => history.push('/create-soil-site')}>
                <FontAwesomeIcon icon="leaf" /> Create a soil site
              </span>
            </DropMenu>
          </Flex>
        </PageHeader>

        <Section>
          <Container>
            <Participation region={selectedRegion} />

            <div style={{ marginTop: '1rem' }}>
              <Impact region={selectedRegion} />
            </div>
          </Container>
        </Section>
      </PageContainer>
    </>
  );
};

export default Insights;

const HideMobile = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: block;
  }
`;

const PageContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const PageHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  height: fit-content;
  background: #fff;
  border-bottom: 1px solid #dfdfdf;
  padding: 0.75rem 1rem;
`;

const Section = styled.section<{ bgColor?: string }>`
  padding: 1rem 0;
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 0 1rem;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 0 1rem;
  }
`;
