import { useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { Flex } from '../../../../components/ui';
import { useClickOutside } from '../../../../hooks/useClickOutside';

export function RegionSelector({ regions, onSelected, defaultRegionId = 1 }) {
  const [open, setOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<number>(defaultRegionId);

  const dropRef = useClickOutside(() => open && setOpen(false));

  const handleItemSelected = (itemId: number) => {
    setSelectedItemId(itemId);
    setOpen(false);
    onSelected(itemId);
  };

  const selectedItem = regions.find((i) => i.id === selectedItemId);

  return (
    <Container>
      <SelectorContainer onClick={() => setOpen((o) => !o)} role="button">
        {selectedItem ? (
          <>
            <Flex align="center" gap="0.5rem">
              {selectedItem.name}
            </Flex>
          </>
        ) : (
          <span>Select Region</span>
        )}
      </SelectorContainer>
      {regions.length > 1 && (
        <AnimatePresence>
          {open && (
            <DropDown
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
              ref={dropRef}
            >
              <ul>
                {regions.map(({ id, name }, i) => (
                  <Item
                    key={id}
                    onClick={() => handleItemSelected(id)}
                    active={selectedItemId === id}
                  >
                    {name}
                  </Item>
                ))}
              </ul>
            </DropDown>
          )}
        </AnimatePresence>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const SelectorContainer = styled.div`
  width: max-content;
  padding: 0.275rem 0.5rem;
  border: 1px solid #ebebeb;
  border-radius: 4px;

  background-color: #f9f9f9;

  &:hover {
    cursor: pointer;
    background-color: #f3f3f3;
  }

  transition: background-color 0.25s ease-in-out;
`;

const DropDown = styled(motion.div)`
  position: fixed;

  margin-top: 0.5rem;
  width: max-content;
  max-height: 70vh;
  overflow-y: hidden;
  z-index: 9999;

  display: flex;
  flex-direction: column;

  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);

  font-size: 0.875rem;
  h5 {
    margin: 0;
    margin-top: 1rem;
    padding: 0 1rem;
    font-size: 0.7rem;
    color: #888;
  }

  hr {
    border: none;
    border-bottom: 1px solid #e2e2e2;
  }
`;

export const Item = styled(motion.li)<{ active: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  padding: 0.5rem 1rem;

  :hover {
    background-color: rgb(242, 242, 242) !important;
    cursor: pointer;
  }

  ${({ active }) =>
    active && `background-color: rgb(242, 242, 242) !important;`}
`;
