import { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { Flex, Grid } from '../../../components/ui';
import { Icon } from '../insights/components/Components';
import Switch from '../insights/components/Switch';
import { InsightBox } from '../insights/components/InsightBox';
import { formatCurrency, formatWeight } from '../../../utils/formatting';
import { theme } from '../../../app/theme';
import { impactCalculator } from '../../impact/impactCalculator';

type ImpactType = 'accumulated' | 'current-rate';
type ImpactBase = 'membership' | 'dropOff';

type Props = {
  foodScrapsInPounds: number;
  householdsParticipating: number;
  showDropOffBasedImpact?: boolean;
};

export const ImpactFeature = ({
  foodScrapsInPounds,
  householdsParticipating,
  showDropOffBasedImpact = false,
}: Props) => {
  const [impactType, setImpactType] = useState<ImpactType>('accumulated');
  const [impactBase, setImpactBase] = useState<ImpactBase>('membership');

  function getImpact() {
    if (impactType === 'current-rate') {
      return impactCalculator.impactRateYearly(householdsParticipating);
    }
    return impactCalculator.impactByWeight(foodScrapsInPounds);
  }

  const { foodWasteSaved, ghg, soilCreated, feesAvoided } = getImpact();

  const suffix = impactType === 'current-rate' ? '/year' : '';

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <Container>
          <Header>
            <Flex gap="0.5rem" align="center">
              <Icon>
                <FontAwesomeIcon icon="trophy" />
              </Icon>

              <strong>IMPACT</strong>
            </Flex>

            <Switch onSelected={setImpactType} defaultValue={impactType}>
              <Switch.SwitchButton value={'accumulated'}>
                total so far
              </Switch.SwitchButton>
              <Switch.SwitchButton value={'current-rate'}>
                current rate
              </Switch.SwitchButton>
            </Switch>
          </Header>

          <MotionContainer
            key={impactType}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {showDropOffBasedImpact && impactType === 'accumulated' && (
              <Header>
                <div />
                <Flex gap="0.5rem" align="center">
                  <HideMobile>
                    <h5 style={{ fontSize: '0.85rem', margin: 0 }}>BASED ON</h5>
                  </HideMobile>

                  <Switch<ImpactBase>
                    onSelected={setImpactBase}
                    defaultValue={impactBase}
                  >
                    <Switch.SwitchButton value="membership">
                      length of participation
                    </Switch.SwitchButton>
                    <Switch.SwitchButton value={'dropOff'}>
                      drop-offs recorded
                    </Switch.SwitchButton>
                  </Switch>
                </Flex>
              </Header>
            )}

            <Content>
              <div>
                <Grid col="repeat(2, 1fr)" gap="1rem">
                  <InsightBox
                    title="Scraps saved from landfill"
                    value={`${formatWeight(foodWasteSaved)}${suffix}`}
                    icon="apple-alt"
                    color={theme.colors.primary}
                    // tooltipText="How do we calculate this?"
                  />
                  <InsightBox
                    title="GHG emissions prevented"
                    value={`${formatWeight(ghg)}${suffix}`}
                    icon="smog"
                    color="#2f6fb1"
                  />
                  <InsightBox
                    title="New soil created"
                    value={`${formatWeight(soilCreated)}${suffix}`}
                    icon="seedling"
                    color="#82671F"
                  />
                  <InsightBox
                    title="Landfill fees avoided"
                    value={`${formatCurrency(feesAvoided)}${suffix}`}
                    icon="dollar"
                    color="#616161"
                  />
                </Grid>
              </div>
            </Content>
          </MotionContainer>
        </Container>
      </AnimatePresence>
    </>
  );
};

const Region = styled.div`
  width: max-content;
  padding: 0.275rem 0.5rem;
  border: 1px solid #ebebeb;
  border-radius: 4px;

  background-color: #f9f9f9;

  &:hover {
    cursor: pointer;
    background-color: #f3f3f3;
  }

  transition: background-color 0.25s ease-in-out;
`;

const HideMobile = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: block;
  }
`;

const Container = styled(motion.div)`
  background-color: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;

  padding: 0.75rem 1rem;

  border-bottom: 1px solid #d7d7d7;

  h4 {
    margin: 0;
  }
`;

const MotionContainer = styled(motion.div)``;

const Content = styled.div`
  padding: 1rem;
`;
