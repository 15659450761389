import styled from 'styled-components';
import { toast } from 'react-toastify';
import { gql, useMutation } from '@apollo/client';
import { ErrorMessage, Field, Form, Formik, FormikErrors } from 'formik';

import {
  Modal,
  FormInputError,
  Flex,
  Button,
  Input,
  TextArea,
} from '../../../components/ui';
import { useAuthUser } from '../../auth/useAuthUser';
import { validationMessages } from '../../../utils/formValidation';
import { FormControl } from '../../admin/components/EmailManagement';
import { isMobileApp } from '../../../mobile/mobile';
import { RecaptchaFormControl } from '../../../components/ui/forms/RecaptchaFormControl';
import { useRef } from 'react';
import { EmailField } from '../../../components/ui/forms/EmailField';

const PREMIUM_INSIGHTS_ENQUIRY = gql`
  mutation PremiumEnquiry($input: ActivateInquiryInput!) {
    premiumInsightsEnquiry(input: $input) {
      errors
      message
    }
  }
`;

type FormData = {
  email: string;
  name: string;
  message: string;
  recaptcha: string;
};

export function InquiryModal({ isOpen, onClose }) {
  const authUser = useAuthUser();

  const recaptchaRef = useRef(null);

  const [sendEnquiry] = useMutation(PREMIUM_INSIGHTS_ENQUIRY);

  const handleSubmit = async (values, { setSubmitting }) => {
    const { name, email, message } = values;

    const { data } = await sendEnquiry({
      variables: {
        input: { email, name, message },
      },
    });

    if (data.premiumInsightsEnquiry) {
      toast.success(data.premiumInsightsEnquiry.message);
    }

    setSubmitting(false);
    onClose();
  };

  const validateForm = (values: FormData) => {
    let errors: FormikErrors<FormData> = {};

    if (!values.email) {
      errors.email = validationMessages.email;
    }

    if (!values.name) {
      errors.name = 'Please provide a name';
    }

    if (!values.message) {
      errors.message = 'Please provide a message.';
    }

    if (!isMobileApp() && !authUser.isLoggedIn && !values.recaptcha) {
      errors.recaptcha = 'Please complete the reCAPTCHA.';
    }

    return errors;
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header="Unlock your impact"
      hideFooter
    >
      <Container>
        <p>You can already activate your community at no cost.</p>

        <p>
          But to track your community’s impact in detail, you need to upgrade to{' '}
          <strong>MakeSoil Pro</strong>.
        </p>

        <p>
          We offer flexible pricing to meet different needs. Please inquire via
          the form and we'll get back to you personally soon.
        </p>
      </Container>

      <Formik
        initialValues={{
          email: authUser.email || '',
          name: '',
          message: '',
          recaptcha: null,
        }}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validate={validateForm}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormControl>
              <Field as={Input} type="text" name="name" placeholder="Name" />
              <ErrorMessage name="name" component={FormInputError} />
            </FormControl>

            <FormControl>
              <EmailField as={Input} name="email" placeholder="Email" />
              <ErrorMessage name="email" component={FormInputError} />
            </FormControl>

            <FormControl>
              <Field as={TextArea} name="message" placeholder="Message" />
              <ErrorMessage name="message" component={FormInputError} />
            </FormControl>

            {!isMobileApp() && !authUser.isLoggedIn && (
              <RecaptchaFormControl
                theme="light"
                name="recaptcha"
                ref={recaptchaRef}
                style={{ marginTop: '1rem' }}
              />
            )}

            <Flex direction="column" gap="1rem" style={{ marginTop: '1.5rem' }}>
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                SEND INQUIRY
              </Button>

              <Button variant="outlined" onClick={onClose}>
                CANCEL
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

const Container = styled.div``;

const Clickable = styled.span`
  color: ${({ theme }) => theme.colors.primary};

  :hover {
    cursor: pointer;
  }
`;
