import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SwipeEventData, useSwipeable } from 'react-swipeable';
import styled from 'styled-components';
import { Flex } from './Flex';

type TabProps = {
  children: React.ReactNode;
  eventKey: string;
  label: string;
  icon?: ReactElement;
  indicator?: number;
};

interface ITab {
  props: TabProps;
}

// this is simply to enforce the props
export const Tab: React.FC<TabProps> = ({ eventKey, label, icon }) => {
  return null;
};

/**
 * Tabs
 */

type TabsProps = {
  children: ITab[];
  className?: string;
  sideGap?: boolean;
  selectedTab?: string;
  placement?: 'top' | 'bottom';
  swipeable?: boolean;
  onTabSelected?: (eventkey: string) => void;
  onSwipeBack?: () => void;
};

export const Tabs: React.FC<TabsProps> = (props) => {
  const {
    children,
    className,
    sideGap = false,
    selectedTab,
    placement = 'top',
    swipeable = true,
    onTabSelected,
    onSwipeBack,
  } = props;

  const firstTab = children[0].props.eventKey;

  const [activeTab, setActiveTab] = useState<string>(selectedTab || firstTab);

  const history = useHistory();

  useEffect(() => {
    if (!selectedTab) {
      setActiveTab(firstTab);
      return;
    }
    setActiveTab(selectedTab);
  }, [children, selectedTab]);

  const onTabSelect = (eventKey: string) => {
    setActiveTab(eventKey);
    if (onTabSelected) {
      onTabSelected(eventKey);
    }
  };

  const handleSwipe = (e: SwipeEventData) => {
    if (!swipeable) return;

    const tabs = children;
    const firstTab = tabs[0].props.eventKey;
    const lastTab = tabs[tabs.length - 1].props.eventKey;
    const currentTab = tabs.find((t) => t.props.eventKey === activeTab).props
      .eventKey;
    const currentTabIndex = tabs.findIndex(
      (t) => t.props.eventKey === activeTab
    );

    if (e.dir === 'Left') {
      if (currentTab !== lastTab) {
        const nextTab = tabs[currentTabIndex + 1].props.eventKey;
        if (nextTab) return onTabSelect(nextTab);
      }
    }

    if (e.dir === 'Right') {
      if (currentTab === firstTab) {
        return onSwipeBack ? onSwipeBack() : history.goBack();
      }

      if (currentTab !== firstTab) {
        const eventKey = tabs[currentTabIndex - 1].props.eventKey;
        if (eventKey) return onTabSelect(eventKey);
      }
    }
  };

  const handlers = useSwipeable({
    onSwiped: handleSwipe,
    delta: 30,
  });

  return (
    <AnimatePresence>
      <TabContainer placement={placement} className={className}>
        <TabList className={placement}>
          {sideGap && <TabNav style={{ maxWidth: '2rem' }} />}
          {children.map((child) => {
            const { label, eventKey, icon, indicator } = child.props;
            return (
              <TabNav
                key={eventKey}
                className={activeTab === eventKey && 'active'}
                onClick={() => onTabSelect(eventKey)}
              >
                <Flex
                  justify="center"
                  align="center"
                  direction="column"
                  style={{ position: 'relative' }}
                >
                  {icon}
                  {indicator && <span className="indicator">{indicator}</span>}

                  <span className="label">{label}</span>
                </Flex>
              </TabNav>
            );
          })}
          {sideGap && <TabNav style={{ maxWidth: '2rem' }} />}
        </TabList>
        <TabContent
          key={activeTab}
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 50 }}
          {...handlers}
        >
          {children.map((child) => {
            if (child.props.eventKey !== activeTab) return undefined;
            return child.props.children;
          })}
        </TabContent>
      </TabContainer>
    </AnimatePresence>
  );
};

const TabContainer = styled.div<{ placement: 'top' | 'bottom' }>`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: ${({ placement }) =>
    placement === 'top' ? `column` : `column-reverse`};
`;

const TabList = styled.ul`
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  margin: 0;

  overflow-x: auto;
`;

const TabNav = styled.li`
  list-style: none;
  padding: 0.5rem 0.75rem;
  background-color: #e7e7e7;
  border-radius: 4px 4px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;

  span.label {
    margin-top: 0.25rem;
    font-size: 0.675rem;
  }

  flex: 1;
  cursor: pointer;

  border-bottom: 3px solid ${({ theme }) => theme.colors.primary};

  &.active {
    background-color: #fff;
    border-bottom: 3px solid transparent;
    color: ${(props) => props.theme.colors.primary};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    background-color: transparent;

    &.active {
      border: solid #e0e0e0;
      border-width: 1px 1px 5px 1px;
      border-bottom: 3px solid transparent;
    }

    &:hover:not(.active) {
      background-color: #e8e8e8;
    }
  }

  div {
    span {
      font-size: 0.875rem;
      text-align: center;
      position: relative;
    }

    span.indicator {
      position: absolute;
      top: -3px;
      right: 0px;

      padding: 0 0.275rem;
      border-radius: 0.5rem;
      background-color: ${({ theme }) => theme.colors.primary};
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 0.75rem;
      font-weight: bold;
      line-height: 1rem;
      color: #fff;
      pointer-events: none;
    }
  }
`;

const TabContent = styled(motion.div)`
  overflow-y: auto;
  flex: 1;
`;
