import { apolloClient } from '../../../../app/apolloClient';
import {
  AssignedRegionItem,
  AssignRegionInput,
  UserFeatureInput,
  PremiumFeatureManaged,
  RemoveRegionInput,
} from './model';
import {
  ASSIGN_REGION,
  REMOVE_REGION,
  ENABLE_FEATURE,
  DISABLE_FEATURE,
  GET_ASSIGNED_REGIONS,
  GET_PREMIUM_FEATURES,
} from './graphql';

export type FeaturesResponse = {
  premiumFeatures: PremiumFeatureManaged[];
};

export type EnableFeatureResponse = {
  enableFeature: { enabled: boolean; enabledAt: Date };
};

export type FetchAssignedRegionsResponse = {
  getAssignedRegions: AssignedRegionItem[];
};

export type AssignedRegionResponse = {
  assignRegion: { success: boolean; region: AssignedRegionItem | null };
};

export type RemoveRegionResponse = {
  removeRegion: boolean;
};

class FeatureManagementService {
  fetchFeatures = (userId: number) => {
    return apolloClient.query<FeaturesResponse>({
      query: GET_PREMIUM_FEATURES,
      variables: { userId },
      fetchPolicy: 'no-cache',
    });
  };

  fetchAssignedRegions = (userId: number) => {
    return apolloClient.query<FetchAssignedRegionsResponse>({
      query: GET_ASSIGNED_REGIONS,
      variables: { userId },
      fetchPolicy: 'no-cache',
    });
  };

  assignRegion = (input: AssignRegionInput) => {
    return apolloClient.mutate<AssignedRegionResponse>({
      mutation: ASSIGN_REGION,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
  };

  removeRegion = (input: RemoveRegionInput) => {
    return apolloClient.mutate<RemoveRegionResponse>({
      mutation: REMOVE_REGION,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
  };

  enableFeature = (input: UserFeatureInput) => {
    return apolloClient.mutate<EnableFeatureResponse>({
      mutation: ENABLE_FEATURE,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
  };

  disableFeature = (input: UserFeatureInput) => {
    return apolloClient.mutate<boolean>({
      mutation: DISABLE_FEATURE,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
  };
}

export default new FeatureManagementService();
