import { ErrorMessage, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch } from '../../../app/hooks';
import { initialize } from '../../../features/locationPicker/locationPickerSlice';

import { validationMessages } from '../../../utils/formValidation';
import { LocationModal } from '../../../features/locationPicker/components/LocationModal';
import { IconButtonWithTooltip } from '../IconButtonWithTooltip';

import { FormInputError } from './FormError';
import { GeocoderResult, GeoSuggestInput } from './GeoSuggestInput';
import { useAuthUser } from '../../../features/auth/useAuthUser';
import { AddressObject } from '../../../app/model/AddressComponents';

type GeosuggestTheme = 'light' | 'dark';

type AddressFieldProps = {
  name?: string;
  placeholder?: string;
  helpText?: string;
  addressType?: 'site' | 'home';
  required?: boolean;
  geosuggestTheme?: GeosuggestTheme;
  showLocationPicker?: boolean;
  onEnterPressed?: () => void;
};

export const AddressField: React.FC<AddressFieldProps> = (props) => {
  const {
    helpText,
    addressType = 'site',
    geosuggestTheme = 'light',
    required = false,
    showLocationPicker = true,
    ...rest
  } = props;

  const {
    values,
    setFieldTouched,
    setFieldError,
    setValues,
    errors,
  } = useFormikContext<AddressObject>();

  const [locationModalOpen, setLocationModalOpen] = useState(false);

  const dispatch = useAppDispatch();
  const authUser = useAuthUser();

  useEffect(() => {
    dispatch(
      initialize({
        existingAddressLocation: {
          location: values.location,
          address: values.address,
        },
        homeLocation: authUser?.location,
      })
    );
  }, [dispatch, authUser?.location, values.address, values.location]);

  const handleLocationConfirmed = (result: GeocoderResult) => {
    setValues(
      {
        ...values,
        address: result.address,
        addressComponents: result.addressComponents,
        googlePlaceId: result.placeId,
        location: {
          lat: result.lat,
          lng: result.lng,
        },
        viewport: result.viewport,
      },
      true
    );

    /**
     * We're clearing the error here because for some reason setting the values does not help
     * if there was an error before. Can't figure out why.
     */
    if (errors.address) {
      setFieldError('address', '');
    }
    setLocationModalOpen(false);
  };

  return (
    <Container geosuggestTheme={geosuggestTheme}>
      <LocationModal
        open={locationModalOpen}
        existingAddressLocation={{
          location: values.location,
          address: values.address,
        }}
        markerIcon={addressType}
        onClose={() => setLocationModalOpen(false)}
        onLocationConfirmed={handleLocationConfirmed}
      />

      <InputContainer>
        <StyledGeosuggestInput
          {...rest}
          initialValue={values.address}
          validate={() => {
            if (required && values.googlePlaceId === '') {
              return validationMessages.soilSite.address;
            }
          }}
          onChange={() => {
            setValues({
              ...values,
              address: '',
              addressComponents: [],
              googlePlaceId: '',
              location: undefined,
              viewport: undefined,
            });
          }}
          onBlur={() => setFieldTouched('address', true, true)}
          onAddressSelected={(result: GeocoderResult) => {
            setValues(
              {
                ...values,
                address: result.address,
                addressComponents: result.addressComponents,
                googlePlaceId: result.placeId,
                location: {
                  lat: result.lat,
                  lng: result.lng,
                },
                viewport: result.viewport,
              },
              true
            );
          }}
        />
        {showLocationPicker && (
          <Trigger
            onClick={() => setLocationModalOpen(true)}
            className="location-trigger"
          >
            <IconButtonWithTooltip
              tooltipText="Set Location on Map"
              icon="map-marked-alt"
              onClick={() => setLocationModalOpen(true)}
            />
          </Trigger>
        )}
      </InputContainer>

      <ErrorMessage name="address" component={FormInputError} />

      {helpText && (
        <HelpText style={{ margin: 0, marginTop: '0.5rem' }}>
          {helpText}
        </HelpText>
      )}
    </Container>
  );
};

const Container = styled.div<{ geosuggestTheme: GeosuggestTheme }>`
  ${({ geosuggestTheme }) =>
    geosuggestTheme === 'light' &&
    `
    .geosuggest .geosuggest__suggests {
      background-color: #fff;
      border: 1px solid #ced4da;

      .geosuggest__item:hover {
        background-color: #f4f4f4;
      }
    }
  `}

  .geosuggest .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
    border-color: transparent;
  }
`;

const HelpText = styled.p`
  text-align: left;
  font-style: italic;
  font-size: 0.875rem;
  color: #a9a9a9;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
  line-height: 1rem;

  margin-top: 0.25rem;

  input.geosuggest__input {
    margin-top: 0 !important;
    border-right: none !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    font-size: 1rem;

    ::placeholder {
      font-style: normal;
      font-size: 1rem !important;
    }
  }
`;

const StyledGeosuggestInput = styled(GeoSuggestInput)`
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: none;
`;

const Trigger = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.5rem;

  background-color: #f4f4f4;

  :hover {
    background-color: #ebebeb;
  }

  border: 1px solid #ced4da;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: none;

  color: ${({ theme }) => theme.colors.primary};

  cursor: pointer;
`;
