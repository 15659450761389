import Chart from 'react-apexcharts';
import { Loader } from '../../../../components/ui';
import { gql, useQuery } from '@apollo/client';
import { theme } from '../../../../app/theme';
import styled from 'styled-components';

const TIMELINE = gql`
  query ParticipationTimeline($region: RegionInput!) {
    participationTimelineInRegion(region: $region) {
      xAxis
      series {
        name
        data
      }
    }
  }
`;

export function ParticipationTimeline({ region }) {
  const { loading, data } = useQuery(TIMELINE, {
    variables: { region },
  });

  if (!data && loading) return <Loader />;

  const { xAxis, series } = data.participationTimelineInRegion;

  return (
    <Container>
      {/* <Header>
        <Icon>
          <FontAwesomeIcon icon="home" />
        </Icon>
        <strong>GROWTH IN HOUSEHOLDS PARTICIPATING</strong>
      </Header> */}
      <Content>
        <Chart
          options={{
            chart: {
              id: 'participation-timeline',
              type: 'area',
              toolbar: {
                show: false,
              },
            },
            colors: [theme.colors.primary],
            dataLabels: {
              enabled: true,
            },
            stroke: {
              curve: 'smooth',
            },
            // title: {
            //   text: 'GROWTH IN HOUSEHOLDS PARTICIPATING',
            //   align: 'left',
            // },
            grid: {
              borderColor: '#e7e7e7',
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5,
              },
            },
            markers: {
              size: 1,
            },
            xaxis: {
              // type: 'datetime',
              categories: xAxis,
              // title: {
              //   text: 'Month',
              // },
            },
            yaxis: {
              showForNullSeries: false,
              labels: {
                formatter: function (val) {
                  return val.toFixed(0);
                },
              },
              // title: {
              //   text: 'Households Participating',
              // },
            },
          }}
          series={series}
          type="line"
          height="100%"
        />
      </Content>
    </Container>
  );
}

const Container = styled.div`
  background-color: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  padding: 0.75rem 1rem;

  border-bottom: 1px solid #d7d7d7;
`;

const Content = styled.div`
  padding: 1rem;

  height: 275px;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 375px;
  }
`;
