import {
  createAsyncThunk,
  createSlice,
  isFulfilled,
  isPending,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  AssignRegionInput,
  PremiumFeatureManaged,
  UserFeature,
  UserFeatureInput,
  AssignedRegionItem,
  RemoveRegionInput,
} from './model';
import { RootState } from '../../../../app/store';
import featureManagementService from './featureManagementService';

type FeatureManagementSliceState = {
  features: PremiumFeatureManaged[];
  selectedFeature: PremiumFeatureManaged;

  loading: boolean;
  error: string;
};

const initialState: FeatureManagementSliceState = {
  features: [],
  selectedFeature: null,

  loading: false,
  error: '',
};

export const featureManagementSlice = createSlice({
  name: 'featureManagement',
  initialState,
  reducers: {
    featureSelected(state, { payload }: PayloadAction<PremiumFeatureManaged>) {
      state.selectedFeature = {
        ...payload,
        assignedRegions: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeatures.fulfilled, (state, { payload }) => {
        state.features = payload;
      })
      .addCase(fetchAssignedRegions.fulfilled, (state, { payload }) => {
        state.selectedFeature.assignedRegions = payload;
      })
      .addCase(assignRegion.fulfilled, (state, { payload }) => {
        if (payload.success && payload.region) {
          state.selectedFeature.assignedRegions.push(payload.region);
        }
      })
      .addCase(removeRegion.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.selectedFeature.assignedRegions = state.selectedFeature.assignedRegions.filter(
            (ar) => ar.id !== payload.regionId && ar.userId === payload.userId
          );
        }
      })
      .addCase(enableFeature.fulfilled, (state, { payload }) => {
        const feature = state.features.find((f) => f.id === payload.featureId);
        feature.enabled = payload.enabled;
        feature.enabledAt = payload.enabledAt;
      })
      .addCase(disableFeature.fulfilled, (state, { payload }) => {
        state.features.find((f) => f.id === payload.featureId).enabled = false;
      })

      // matchers
      .addMatcher(
        isPending(fetchFeatures, enableFeature, disableFeature),
        (state) => {
          state.loading = true;
          state.error = '';
        }
      )
      .addMatcher(
        isFulfilled(fetchFeatures, enableFeature, disableFeature),
        (state) => {
          state.loading = false;
          state.error = '';
        }
      );
  },
});

export default featureManagementSlice.reducer;

//
//
// actions
export const { featureSelected } = featureManagementSlice.actions;

//
//
// selectors

export const selectFeatures = (state: RootState) =>
  state.admin.featureManagement.features;

export const selectSelectedFeature = (state: RootState) =>
  state.admin.featureManagement.selectedFeature;

export const fetchFeatures = createAsyncThunk<PremiumFeatureManaged[], number>(
  'featureManagement/fetchFeatures',
  async (userId, { rejectWithValue }) => {
    const { data, errors } = await featureManagementService.fetchFeatures(
      userId
    );
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return data.premiumFeatures;
  }
);

export const enableFeature = createAsyncThunk<
  { enabled: boolean; enabledAt: Date; featureId: number },
  UserFeatureInput
>(
  'featureManagement/enableFeature',
  async ({ userId, featureId }, { rejectWithValue }) => {
    const { data, errors } = await featureManagementService.enableFeature({
      userId,
      featureId,
    });
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return { ...data.enableFeature, featureId };
  }
);

export const disableFeature = createAsyncThunk<UserFeature, UserFeatureInput>(
  'featureManagement/disableFeature',
  async ({ userId, featureId }, { rejectWithValue }) => {
    const { data, errors } = await featureManagementService.disableFeature({
      userId,
      featureId,
    });
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return { featureId, userId };
  }
);

//
//
// Regions

export const fetchAssignedRegions = createAsyncThunk<
  AssignedRegionItem[],
  number
>(
  'featureManagement/fetchAssignedRegions',
  async (userId, { rejectWithValue }) => {
    const {
      data,
      errors,
    } = await featureManagementService.fetchAssignedRegions(userId);
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return data.getAssignedRegions;
  }
);

export const assignRegion = createAsyncThunk<
  {
    success: boolean;
    region: AssignedRegionItem;
  },
  AssignRegionInput
>('featureManagement/assignRegion', async (input, { rejectWithValue }) => {
  const { data, errors } = await featureManagementService.assignRegion(input);
  if (!data && errors) {
    return rejectWithValue(errors[0]);
  }

  return data.assignRegion;
});

export const removeRegion = createAsyncThunk<
  { success: boolean; regionId: number; userId: number },
  RemoveRegionInput
>('featureManagement/removeRegion', async (input, { rejectWithValue }) => {
  const { data, errors } = await featureManagementService.removeRegion(input);
  if (!data && errors) {
    return rejectWithValue(errors[0]);
  }

  return { success: data.removeRegion, ...input };
});
