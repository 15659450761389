import React, { useState } from 'react';
import Geosuggest from 'react-geosuggest';
import { Field } from 'formik';
import styled from 'styled-components';

interface Coordinates {
  lat: number;
  lng: number;
}

interface GeoSuggest {
  label: string;
  placeId: string;
  location: Coordinates;
  gmaps: google.maps.GeocoderResult;
}

export interface GeocoderResult {
  address: string;
  addressComponents: {
    longName: string;
    shortName: string;
    componentType: string[];
  }[];
  placeId: string;
  lat: number;
  lng: number;
  viewport: google.maps.LatLngBoundsLiteral;
}

type GeoSuggestInputProps = {
  name?: string;
  placeholder?: string;
  initialValue: any;
  placeDetailFields?: string[];
  onChange?: (e) => void;
  onBlur?: (e) => void;
  validate?: (value: string) => void;
  onAddressSelected: (geolocationResult: GeocoderResult) => void;
  onEnterPressed?: () => void;
  className?: string;
  style?: React.CSSProperties;
};

export const GeoSuggestInput: React.FC<GeoSuggestInputProps> = (props) => {
  const {
    name = 'address',
    placeholder = 'Start typing the address...',
    placeDetailFields = [
      'address_components',
      'formatted_address',
      'geometry',
      'place_id',
    ],
    initialValue,
    onChange,
    onBlur,
    onAddressSelected,
    onEnterPressed,
    validate,
    style,
  } = props;

  const [, setAddress] = useState(null);

  const formatAddress = (geoSuggest: GeoSuggest) => {
    const {
      gmaps,
      label,
      location,
      placeId,
    }: {
      gmaps: google.maps.GeocoderResult;
      label: string;
      location: Coordinates;
      placeId: string;
    } = geoSuggest;

    return {
      address: gmaps.formatted_address || label,
      addressComponents: gmaps.address_components.map((comp) => ({
        longName: comp.long_name,
        shortName: comp.short_name,
        componentType: comp.types,
      })),
      placeId,
      lat: location.lat,
      lng: location.lng,
      viewport: gmaps.geometry.viewport.toJSON(),
    };
  };

  const handleAddressSelected = (geoSuggest: GeoSuggest) => {
    if (!geoSuggest || !geoSuggest.location) return;
    if (!geoSuggest.placeId)
      throw new Error('Please select an address from the dropdown.');

    const address = formatAddress(geoSuggest);
    onAddressSelected(address);
  };

  /* This makes sure we clear a Geosuggest that was selected but
   * then deleted from the input box. Otherwise it will stay in state until
   * another Geosuggest was chosen, potentially associating a user with
   * an incorrect address.
   */
  const handleGeoChange = (value) => {
    if (!value) setAddress(null);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <GeosuggestField
      {...props}
      style={style}
      type="text"
      name={name}
      placeholder={placeholder}
      initialValue={initialValue}
      placeDetailFields={placeDetailFields}
      component={Geosuggest}
      validate={validate}
      autoComplete="off"
      onChange={handleGeoChange}
      onBlur={onBlur}
      onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
          onEnterPressed && onEnterPressed();
        }
      }}
      onSuggestSelect={(suggest: GeoSuggest) => handleAddressSelected(suggest)}
    />
  );
};

const GeosuggestField = styled(Field)`
  &.geosuggest {
    font-size: 18px;
    font-size: 1rem;
    position: relative;
    width: 100%;
    text-align: left;

    .geosuggest__input {
      padding: 0.5rem;
      height: 2.25rem;
      width: 100%;
      border: 1px solid #ced4da;
      border-radius: ${({ theme }) => theme.borderRadius}px;
      color: #7b7b7b;
      outline: none;
    }
    .geosuggest__input:focus {
      border-color: #267dc0;
      box-shadow: 0 0 0 transparent;
    }
    .geosuggest__suggests {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      max-height: 25em;
      padding: 0;
      margin-top: -1px;
      background: #252525;
      border: 1px solid #111111;
      border-top-width: 0;
      overflow-x: hidden;
      overflow-y: auto;
      list-style: none;
      z-index: 5;
      -webkit-transition: max-height 0.2s, border 0.2s;
      transition: max-height 0.2s, border 0.2s;
    }
    .geosuggest__suggests--hidden {
      max-height: 0;
      overflow: hidden;
      border-width: 0;
    }
    .geosuggest__item {
      font-size: 18px;
      font-size: 1rem;
      padding: 0.5em 0.65em;
      cursor: pointer;
    }
    .geosuggest__item:hover,
    .geosuggest__item:focus {
      background: #191919;
    }
    .geosuggest__item--active {
      background: #267dc0;
      color: #fff;
    }
    .geosuggest__item--active:hover,
    .geosuggest__item--active:focus {
      background: #ccc;
    }
    .geosuggest__item__matched-text {
      font-weight: bold;
    }
  }
`;
