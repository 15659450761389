import React, { Fragment } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from '../../app/hooks';
import { selectUnreadMessagesCount } from '../../features/messenger/messengerSlice';
import { SwipeEventData, useSwipeable } from 'react-swipeable';
import { SmartLink } from './SmartLink';
import { useAuthUser } from '../../features/auth/useAuthUser';
import { selectNavigationOpen } from '../../features/layout/layoutSlice';
import { useSidenavToggle } from '../../hooks/useSideNavToggle';

type SideNavProps = {
  showAdmin?: boolean;
  showInsights?: boolean;
};

export const SideNav: React.FC<SideNavProps> = ({
  showAdmin = false,
  showInsights = false,
}) => {
  const authUser = useAuthUser();
  const location = useLocation();
  const sideNavigationOpen = useAppSelector(selectNavigationOpen);
  const unreadMessagesCount = useAppSelector(selectUnreadMessagesCount);

  const { hideNavigationAfterClikedInMobile } = useSidenavToggle();

  const handleSwipe = (e: SwipeEventData) => {
    if (e.dir === 'Left') {
      hideNavigationAfterClikedInMobile();
    }
  };

  const handlers = useSwipeable({
    onSwiped: handleSwipe,
    delta: 30,
  });

  return (
    <Wrapper>
      <SideNavigationContainer
        collapsed={!sideNavigationOpen}
        onClick={hideNavigationAfterClikedInMobile}
        {...handlers}
      >
        <nav>
          <ul>
            <li>
              <NavLink to="/map" activeClassName="active">
                <FontAwesomeIcon
                  icon="map-marked-alt"
                  title="Find a soil site"
                />
                <span className="text">Find a Soil Site</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to={{
                  pathname: '/create-soil-site',
                  state: { action: 'create' },
                }}
                activeClassName="active"
              >
                <FontAwesomeIcon icon="leaf" title="Create Soil Site" />
                <span className="text">Create a Soil Site</span>
              </NavLink>
            </li>

            <li>
              <NavLink to="/soil-sponsors" activeClassName="active">
                <FontAwesomeIcon icon="heart" title="Become a Soil Sponsor" />
                <span className="text">Become a Soil Sponsor</span>
              </NavLink>
            </li>

            <li className={!authUser.isLoggedIn ? 'disabled' : ''}>
              <NavLink
                to="/my-soil-sites"
                activeClassName="active"
                onClick={(e) => !authUser.isLoggedIn && e.preventDefault()}
              >
                <FontAwesomeIcon icon="map-marker-alt" title="My Soil Sites" />
                <span className="text">My Soil Sites</span>
              </NavLink>
            </li>

            <li>
              <NavLink to="/pro/access-activate" activeClassName="active">
                <FontAwesomeIcon icon="chart-line" title="Activate" />
                <span className="text">Activate</span>
              </NavLink>
            </li>

            <li
              style={{ position: 'relative' }}
              className={!authUser.isLoggedIn ? 'disabled' : ''}
            >
              <NavLink
                to="/messages"
                activeClassName="active"
                onClick={(e) => !authUser.isLoggedIn && e.preventDefault()}
              >
                <FontAwesomeIcon icon="envelope" title="Messages" />
                <span className="text">Messages</span>
                {unreadMessagesCount > 0 && (
                  <CountIndicator collapsed={!sideNavigationOpen}>
                    {unreadMessagesCount}
                  </CountIndicator>
                )}
              </NavLink>
            </li>

            <li>
              <NavLink to="/learn" activeClassName="active">
                <FontAwesomeIcon icon="graduation-cap" title="Learn" />
                <span className="text">Learn</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/posters-and-flyers" activeClassName="active">
                <FontAwesomeIcon
                  icon="file-invoice"
                  title="Poster &amp; Flyers"
                />
                <span className="text">Poster &amp; Flyers</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/diy" activeClassName="active">
                <FontAwesomeIcon icon="archive" title="DIY" />
                <span className="text">DIY</span>
              </NavLink>
            </li>
          </ul>
        </nav>
        <BottomNav collapsed={!sideNavigationOpen}>
          <nav>
            <ul>
              <li>
                <NavLink to="/contact-us" activeClassName="active">
                  <FontAwesomeIcon
                    icon="envelope-open-text"
                    title="Contact Us"
                  />
                  <span className="text">Contact Us</span>
                </NavLink>
              </li>

              {showAdmin && (
                <Fragment>
                  <hr />
                  <li>
                    <NavLink to="/admin" activeClassName="active">
                      <FontAwesomeIcon icon="user-shield" title="Admin" />
                      <span className="text">Admin</span>
                    </NavLink>
                  </li>
                </Fragment>
              )}
              {showInsights && (
                <>
                  <hr />
                  <li>
                    <NavLink to="/insights" activeClassName="active">
                      <FontAwesomeIcon icon="chart-line" title="Insights" />
                      <span className="text">Insights</span>
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          </nav>

          <footer>
            <hr />
            <SocialIcons>
              <SmartLink href={'https://facebook.com/MakeSoil'}>
                <FontAwesomeIcon size="2x" icon={['fab', 'facebook-square']} />
              </SmartLink>
              <SmartLink href={'https://www.instagram.com/makesoil'}>
                <FontAwesomeIcon size="2x" icon={['fab', 'instagram-square']} />
              </SmartLink>
              <SmartLink href={'https://twitter.com/MakeSoil'}>
                <FontAwesomeIcon size="2x" icon={['fab', 'square-x-twitter']} />
              </SmartLink>
              <SmartLink href={'https://www.youtube.com/c/MakeSoil'}>
                <FontAwesomeIcon size="2x" icon={['fab', 'youtube-square']} />
              </SmartLink>
            </SocialIcons>
            <TermsPrivacy>
              <Link to="/terms">
                <div className="Navigation-terms makesoil-medium-grey">
                  Terms of Use
                </div>
              </Link>
              <Link to="/privacy">
                <div className="Navigation-terms makesoil-medium-grey">
                  Privacy Policy
                </div>
              </Link>
            </TermsPrivacy>
            <Copyright>&copy; {new Date().getFullYear()} MakeSoil</Copyright>
          </footer>
        </BottomNav>
      </SideNavigationContainer>
      <Backdrop
        show={sideNavigationOpen}
        onClick={hideNavigationAfterClikedInMobile}
      />
    </Wrapper>
  );
};

//#region Styles

type StyleProps = {
  collapsed: boolean;
};

const Wrapper = styled.div`
  height: 100%;
  overflow: hidden;
  // z-index: 16;
`;

const Backdrop = styled.div<{ show: boolean }>`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    ${({ show }) =>
      show &&
      `
      position: absolute;
      top: calc(61px + env(safe-area-inset-top));
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 13;

      background-color: rgba(0, 0, 0, 0.7);
      transition: all 0.35s ease;
  `}
  }
`;

const SideNavigationContainer = styled.div<StyleProps>`
  grid-area: side-nav;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: absolute;
  height: 100%;
  width: ${({ collapsed }) => (collapsed ? '0' : '80vw')};
  padding: 1rem 0;
  z-index: 15;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    position: relative;
    max-width: 250px;
    top: 0;
    height: 100%;

    ${({ collapsed }) =>
      collapsed &&
      `
			padding: 0;
			width: 60px;
		`}
  }

  background: ${({ theme }) => theme.colors.background};

  overflow-y: auto;
  transition: all 0.1s ease-in;

  // hide scrollbar
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      a {
        padding: 0.875rem 1.25rem;
        text-decoration: none;
        color: #d9d9d9;

        display: flex;

        svg {
          min-width: 20px;
          text-align: center;
        }

        .text {
          overflow: hidden;
          opacity: 1;
          white-space: nowrap;
          width: 100%;
          margin-left: 0.75rem;

          // hide text only above breakpoint when collapsed
          @media (min-width: ${(props) => props.theme.breakpoints.md}) {
            margin-left: ${({ collapsed }) => (collapsed ? '0' : '0.75rem')};
            width: ${({ collapsed }) => collapsed && '0'};
          }

          transition: all 0.35s ease;
        }

        &:hover {
          background-color: #2f2f2f;
          color: #ececec;
        }

        transition: all 0.35s ease;
      }

      a.active {
        background: #424242;
        border-left: 3px solid ${(props) => props.theme.colors.primary};
      }

      &.disabled {
        a {
          color: #9d9d9d;
          cursor: default;

          :hover {
            background-color: inherit;
            color: #9d9d9d;
          }
        }
      }
    }
  }

  hr {
    border-color: #333;
    width: 100%;
    margin: 0;
  }
`;

const CountIndicator = styled.span<{ collapsed: boolean }>`
  position: absolute;
  top: 13px;
  right: 12px;

  padding: 0 0.275rem;
  border-radius: 0.5rem;

  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1rem;
  color: #fff;

  ${({ collapsed }) =>
    collapsed &&
    `
    position: absolute;
    top: 7px;
    right: 10px;
  `}

  transition: all ${({ theme }) => theme.timing}s ease;
`;

const BottomNav = styled.div<StyleProps>`
  margin-top: 2rem;

  footer {
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow: hidden;

    transition: all 0.35s ease;

    ${({ collapsed }) =>
      collapsed &&
      `
				max-height: 0.0001px;
			`};
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.25rem 0.5rem;
  color: #a5a5a5;

  svg {
    cursor: pointer;

    &:hover {
      color: #d4d4d4;
    }
  }
`;

const TermsPrivacy = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.25rem 0.5rem;
  font-size: 0.875rem;
  color: #a5a5a5;

  a {
    &:hover {
      color: #d4d4d4;
    }
  }
`;

const Copyright = styled.div`
  padding: 0 1.25rem;
  font-size: 0.95rem;
`;

//#endregion
