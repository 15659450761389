import { AppRole } from '../../../app/model/AppRole';
import { User } from '../../../app/model/User';
import { apolloClient } from '../../../app/apolloClient';
import {
  PARTICIPATION_DETAILS,
  ACCOUNT_ACTIVITY,
  ASSIGN_ROLE,
  DELETE_USER_ACCOUNT,
  GET_USER_ROLES,
  GET_VERIFICATION_CODE,
  REGENERATE_VERIFICATION_CODE,
  SEARCH_USERS,
  VERIFY_USER_ACCOUNT,
} from './graphql';

import { PremiumFeatureManaged } from './premium/model';

export type UsersByKeywordsResponse = {
  usersByKeywords: User[];
};

export type RolesResponse = {
  userRoles: AppRole[];
};

export type FeaturesResponse = {
  premiumFeatures: PremiumFeatureManaged[];
};

export type AssignRoleResponse = {
  assignRole: {
    id: number;
    roles: AppRole[];
  };
};

export type ParticipationDetails = {
  sites: {
    role: string;
    siteName: string;
    siteUrl: string;
  }[];
  accessRequests: {
    siteName: string;
    siteUrl: string;
    createdAt: Date;
  }[];
};

type FetchParticipationDetailsResponse = {
  participationDetails: ParticipationDetails;
};

export type LoginDetails = {
  createdAt: Date;
  loginType: string;
  useragent: any;
};

export type AccountActivity = {
  lastLogin: {
    app: LoginDetails;
    web: LoginDetails;
  };
};

type FetchAccountActivityResponse = {
  accountActivity: AccountActivity;
};

type FetchVerificationCodeResponse = {
  verificationCode: { code: string; updatedAt: Date };
};

type RegenerateVerificationCodeResponse = {
  regenerateVerificationCode: { code: string; updatedAt: Date };
};

export enum VERIFICATION_STATE {
  VERIFIED = 'verified',
  EXPIRED = 'expired',
  INVALID = 'invalid',
}

type VerifyUserAccountResponse = {
  status: VERIFICATION_STATE;
};

export type DeleteUserAccountResponse = {
  deleteUserAccount: { errors?: string[]; message: string };
};

class UserManagementService {
  fetchUsersByKeywords = (keywords: string) => {
    return apolloClient.query<UsersByKeywordsResponse>({
      query: SEARCH_USERS,
      variables: { keywords },
      fetchPolicy: 'no-cache',
    });
  };

  fetchRoles = () => {
    return apolloClient.query<RolesResponse>({
      query: GET_USER_ROLES,
      fetchPolicy: 'no-cache',
    });
  };

  assignRole = (userId: number, roleId: number) => {
    return apolloClient.mutate<AssignRoleResponse>({
      mutation: ASSIGN_ROLE,
      variables: { userId, roleId },
      fetchPolicy: 'no-cache',
    });
  };

  fetchParticipationDetails = (userId: number) => {
    return apolloClient.query<FetchParticipationDetailsResponse>({
      query: PARTICIPATION_DETAILS,
      variables: { userId },
    });
  };

  fetchAccountActivity = (userId: number) => {
    return apolloClient.query<FetchAccountActivityResponse>({
      query: ACCOUNT_ACTIVITY,
      variables: { userId },
    });
  };

  fetchVerificationCode = (userId: number) => {
    return apolloClient.query<FetchVerificationCodeResponse>({
      query: GET_VERIFICATION_CODE,
      variables: { userId },
      fetchPolicy: 'no-cache',
    });
  };

  regenerateVerificationCode = (userId: number) => {
    return apolloClient.query<RegenerateVerificationCodeResponse>({
      query: REGENERATE_VERIFICATION_CODE,
      variables: { userId },
      fetchPolicy: 'no-cache',
    });
  };

  verifyUserAccount = (userId: number, code: string) => {
    return apolloClient.mutate<VerifyUserAccountResponse>({
      mutation: VERIFY_USER_ACCOUNT,
      variables: { userId, code },
      fetchPolicy: 'no-cache',
    });
  };

  deleteUserAccount = (userId: number) => {
    return apolloClient.mutate<DeleteUserAccountResponse>({
      mutation: DELETE_USER_ACCOUNT,
      variables: { userId },
      fetchPolicy: 'no-cache',
    });
  };
}

export default new UserManagementService();
