import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Flex, Tabs } from '../../../components/ui';
import { useSoilSiteContext } from '../SoilSiteContext';
import { useAuthUser } from '../../auth/useAuthUser';
import { ImpactTooltip } from '../../impact/ImpactTooltip';
import { ImpactCounters } from '../../impact/ImpactCounters';
import { isAdmin } from '../../auth/utils';
import { Counter } from '../../../components/Counter';
import { Tab } from '../../../components/ui/Tabs';

type Props = {
  onRegisterDropOff: () => void;
  onAddSupporters: () => void;
};

export function SiteImpact({ onRegisterDropOff, onAddSupporters }: Props) {
  const authUser = useAuthUser();

  const {
    soilSite: { name, members, dropOffs },
  } = useSoilSiteContext();

  const participant = members.find((p) => p.userId === authUser.id);

  // if non member Admin visits the site use empty array for my drop offs
  const myDropOffs =
    !participant && isAdmin(authUser) ? [] : participant.dropOffs;

  return (
    <Container>
      <Header align="center" justify="space-between">
        <Flex align="center" gap="0.325rem">
          <Icon>
            <FontAwesomeIcon icon="trophy" />
          </Icon>
          <p style={{ margin: 0 }}>
            IMPACT at <strong>{name}</strong>
          </p>
        </Flex>
        <ImpactTooltip visible={dropOffs.length > 0} />
      </Header>

      <StyledTabs selectedTab={'total-drop-offs'} placement={'top'}>
        <Tab eventKey="total-drop-offs" label="Total drop-offs">
          <BlockContainer>
            <SiteDropOffs dropOffs={dropOffs} />

            <small
              style={{
                display: 'block',
                marginTop: '0.75rem',
              }}
            >
              To see more drop-offs,{' '}
              <Button variant="link" onClick={() => onAddSupporters()}>
                add more Soil Supporters
              </Button>
              .
            </small>
          </BlockContainer>
        </Tab>
        <Tab eventKey="my-drop-offs" label="My drop-offs">
          <BlockContainer>
            <MyDropOffs dropOffs={myDropOffs} />
          </BlockContainer>
        </Tab>
      </StyledTabs>

      <BlockContainer>
        <Button
          onClick={onRegisterDropOff}
          style={{ width: '100%', marginTop: '0.75rem' }}
        >
          <FontAwesomeIcon icon="file-signature" /> Record a drop-off
        </Button>
      </BlockContainer>
    </Container>
  );
}

function MyDropOffs({ dropOffs }) {
  return (
    <div style={{ paddingTop: '0.75rem' }}>
      <Counters>
        <Counter count={dropOffs.length} text="My drop-offs recorded" />
        <ImpactCounters dropOffs={dropOffs} />
      </Counters>
    </div>
  );
}

function SiteDropOffs({ dropOffs }) {
  return (
    <div style={{ paddingTop: '0.75rem' }}>
      <Counters>
        <Counter count={dropOffs.length} text="Total drop-offs recorded" />
        <ImpactCounters dropOffs={dropOffs} />
      </Counters>
    </div>
  );
}

const Header = styled(Flex)`
  border-bottom: 1px solid #dedede;
  padding: 0.75rem;

  p {
    margin: 0;
  }
`;

const Icon = styled.div`
  padding: 0.25rem;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
`;

const Counters = styled.div`
  border: 1px solid #dedede;
  border-radius: 0.25rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0.75rem;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d7d7d7;
  box-shadow: 0 0 3px rgba(91, 91, 91, 0.1);

  p {
    margin: 0;
    font-size: 0.925rem;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 1rem;
    }
  }
`;

const BlockContainer = styled.div`
  padding: 0 0.75rem;
`;

const StyledTabs = styled(Tabs)`
  ul {
    border-bottom: 1px solid #dbdbdb;
  }

  li span.label {
    font-size: 0.775rem;
    font-weight: 500;
  }

  li.active {
    border-bottom-color: ${({ theme }) => theme.colors.primary} !important;
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-top: none;
    border-radius: unset;
    background-color: rgba(69, 177, 57, 0.03);
  }

  li:not(.active) {
    border-bottom-color: transparent !important;

    &:hover {
      background-color: #ebebeb;
      border-radius: unset;
    }
  }
`;
