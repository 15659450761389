import { ReactNode } from 'react';
import styled from 'styled-components';

type Mode = 'light' | 'dark';

type CardProps = {
  image: ReactNode;
  content: ReactNode;
  actions: ReactNode;
  footer: ReactNode;
  className?: string;
  mode?: Mode;
};

export const Card: React.FC<CardProps> = ({
  image,
  content,
  actions,
  footer,
  className,
  mode = 'light',
}) => {
  return (
    <CardContainer className={className} mode={mode}>
      {image}
      <CardContent>{content}</CardContent>
      <CardActions mode={mode}>{actions}</CardActions>
      <CardFooter mode={mode}>{footer}</CardFooter>
    </CardContainer>
  );
};

const CardContainer = styled.div<{ mode?: Mode }>`
  display: flex;
  flex-direction: column;

  min-width: 300px;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-width: 300px;
  }

  background-color: #fff;

  ${({ mode }) =>
    mode === 'dark' &&
    `
    background-color: rgba(51, 51, 51, 0.8);
    border: 1px solid #5f5f5f;
  `}

  border: 1px solid #d7d7d7;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  transition: 0.25s ease;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: auto;
  padding: 1rem;
  text-align: center;

  h3,
  h4,
  p {
    margin: 0;
  }
  p {
    margin-top: 0.5rem;
  }
`;

const CardActions = styled.div<{ mode?: Mode }>`
  display: flex;
  justify-content: center;
  align-items: center;

  border-top: 1px solid #d7d7d7;

  ${({ mode }) =>
    mode === 'dark' &&
    `
    border-top: 1px solid #5f5f5f;
  `}

  padding: 1rem 0.5rem;

  a {
    padding: 0.75rem 1rem;
    margin: 0 0.5rem;
    flex: 1;

    font-size: 0.875rem;
    text-align: center;
    color: #fff;

    border: 1px solid ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.primary};

    &.outlined {
      background-color: #fff;
      border: 1px solid ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

const CardFooter = styled.div<{ mode?: Mode }>`
  background-color: #f8f8f8;

  ${({ mode }) =>
    mode === 'dark' &&
    `
    background-color: rgba(41, 41, 41, 0.9);
  `}

  padding: 0.75rem;
  text-align: center;

  border-top: 1px solid #d7d7d7;
  border-radius: 0 0 4px 4px;
`;
