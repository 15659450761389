import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  fade?: boolean;
  scale?: boolean;
  height?: boolean;
  duration?: number;
};

export const Revealer: React.FC<Props> = ({
  children,
  isOpen,
  fade = false,
  scale = true,
  height = true,
  duration = 0.5,
}) => {
  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.div
          key="content"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: fade ? 0 : 1, height: height ? 0 : 'auto' },
          }}
          transition={{ duration, ease: [0.04, 0.62, 0.23, 0.78] }}
          style={{ overflow: 'hidden' }}
        >
          <motion.div
            variants={{
              collapsed: { scale: scale ? 0.9 : 1 },
              open: { scale: 1 },
            }}
            transition={{ duration }}
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
