import { Redirect } from 'react-router-dom';
import { useAuthUser } from '../auth/useAuthUser';

export default function ActivateRouter() {
  const authUser = useAuthUser();

  // if not logged in redirect to product page
  if (!authUser.isLoggedIn) {
    return <Redirect to="/activate" />;
  }

  return <Redirect to="/pro/activate" />;
}
