import styled from 'styled-components';

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 1.75rem;
  height: 1.75rem;

  padding: 0.35rem;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
`;
