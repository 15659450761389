import { ComponentProps } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Box, Flex } from '../../../../components/ui';
import { Tooltip } from '../../../../components/ui/Tooltip';
import { theme } from '../../../../app/theme';

type InsightBoxProps = ComponentProps<'div'> & {
  title: string;
  value: string | number;
  color: string;
  icon?: IconProp;
  tooltipText?: string;
};

export const InsightBox = ({
  title,
  value,
  color,
  icon,
  tooltipText,
  style,
}: InsightBoxProps) => {
  return (
    <InsightBoxContainer style={style}>
      <IconHolder color={color}>
        <FontAwesomeIcon icon={icon} />
      </IconHolder>
      <Content>
        <Flex align="center" justify="space-between" gap="0.25rem">
          <h4>{title}</h4>
          {tooltipText && (
            <Tooltip
              triggerElement={
                <span style={{ fontSize: '1rem' }}>
                  <FontAwesomeIcon
                    icon="info-circle"
                    color={theme.colors.primary}
                  />
                </span>
              }
            >
              {tooltipText}
            </Tooltip>
          )}
        </Flex>
        <Value>{value}</Value>
      </Content>
    </InsightBoxContainer>
  );
};

const IconHolder = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;

  flex: 2;
  max-width: 135px;

  border-radius: 4px 0 0 4px;
  background-color: ${(props) => props.color};
  font-size: 2rem;
  color: #fff;
`;

const Content = styled.div`
  flex: 5;

  padding: 0.75rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 1rem;
  }

  h4 {
    font-size: 0.875rem;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0;
  }
`;

const Value = styled.span`
  display: inline-block;
  margin-top: 0.25rem;
  font-weight: 600;
  font-size: 1.75rem;
`;

const InsightBoxContainer = styled(Box)`
  display: flex;
  flex: 1;

  padding: 0;
`;
