import { storage } from './storage';

export function formatDistance(metres: number) {
  const locale = new Intl.NumberFormat().resolvedOptions().locale;
  let unit = 'kilometer';
  if (['en-US', 'en-GB'].includes(locale)) {
    unit = 'mile';
  }

  const units = {
    kilometer: 1000,
    mile: 1609,
  };

  let distance;

  switch (unit) {
    case 'kilometer':
      distance = metres / 1000;
      break;
    case 'mile':
      distance = metres * 0.000621371192;
      break;
    default:
      break;
  }

  const formatter = new Intl.NumberFormat(undefined, {
    notation: 'compact',
    style: 'unit',
    unit,
    unitDisplay: 'long',
    maximumFractionDigits: 1,
    maximumSignificantDigits: 1,
  });

  // small distance show => "less than 0.1 m/km"
  if (metres < units[unit] / 10) {
    return `< ${formatter.format(0.1)}`;
  }

  return formatter.format(distance);
}

export type WeightUnit = 'kilogram' | 'pound';

export function getWeightUnit(): { unit: WeightUnit } {
  const locale = new Intl.NumberFormat().resolvedOptions().locale;

  let weightUnit: { unit: WeightUnit } = { unit: 'kilogram' };
  if (['en-US', 'en-GB'].includes(locale)) {
    weightUnit = { unit: 'pound' };
  }

  weightUnit = storage.local.get('weight-unit') || weightUnit;

  return weightUnit;
}

export function formatWeight(weight: number, unit?: WeightUnit) {
  const formatter = new Intl.NumberFormat(undefined, {
    notation: 'compact',
    style: 'unit',
    unit: unit || getWeightUnit().unit,
    unitDisplay: 'short',
    maximumFractionDigits: 1,
    // maximumSignificantDigits: 1,
  });

  return formatter.format(weight);
}

export function formatCurrency(money: number) {
  const formatter = new Intl.NumberFormat(undefined, {
    notation: 'compact',
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 2,
    // maximumSignificantDigits: 1,
  });

  return formatter.format(money);
}
