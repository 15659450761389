import React from 'react';
import styled from 'styled-components';

type GridProps = {
  /** grid-template-columns: (ex. repeat(3, 1fr)) */
  gap: string;
  col?: string;
  columns?: number;
  view?: 'grid' | 'list';
  style?: React.CSSProperties;
};

export const Grid: React.FC<GridProps> = ({
  children,
  col,
  columns,
  view,
  gap,
  style,
}) => {
  const columnsNum = columns ? columns : view && view === 'grid' ? 2 : 1;

  return (
    <GridContainer col={col} gap={gap} columns={columnsNum} style={style}>
      {children}
    </GridContainer>
  );
};

const GridContainer = styled.div<GridProps>`
  display: grid;
  grid-template-rows: auto;

  ${({ gap }) => gap && `grid-gap: ${gap};`}

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    ${({ columns }) =>
      columns && `grid-template-columns: repeat(${columns}, minmax(0, 1fr));`}

    ${({ col }) => col && `grid-template-columns: ${col};`}
  }
`;
