import React, { useState } from 'react';
import styled from 'styled-components';

import { Button, Flex, Modal, Revealer, Tabs } from '../../../../components/ui';
import { Tab } from '../../../../components/ui/Tabs';
import { QrCode } from '../../../../components/QrCode';
import { InviteByLink } from '../../../site/invitation/InviteByLink';
import { useHistory } from 'react-router-dom';

export type ActivateModalTab = 'qr' | 'link' | 'resources' | 'create-soil-site';

type ActivateModalProps = {
  isOpen: boolean;
  onClose: () => void;
  tab: ActivateModalTab;
};

export const ActivateModal: React.FC<ActivateModalProps> = (props) => {
  const { isOpen, onClose, tab = 'qr' } = props;

  const history = useHistory();

  const [selectedTab, setSelectedTab] = useState(tab);

  const showInviteByLink = true;
  const inviteUrl = 'https://makesoil.org';

  return (
    <Modal
      header={`Activate your community`}
      isOpen={isOpen}
      onClose={onClose}
      noPadding
    >
      <div style={{ padding: '1rem 1rem 0' }}>
        <HelpText>
          Even getting just <strong>one more household</strong> contributing
          their scraps and helping to make soil, plays a vital part in repairing
          the Earth.
        </HelpText>
      </div>

      <StyledTabs
        selectedTab={selectedTab}
        onTabSelected={(tab: ActivateModalTab) => setSelectedTab(tab)}
      >
        <Tab eventKey="qr" label="QR Code">
          <TabContent>
            <HelpText>
              Use this QR code on your promotional channels & materials (e.g.
              website, social media, flyers etc).
            </HelpText>

            <Flex justify="center">
              <QrCode text={inviteUrl} />
            </Flex>
          </TabContent>
        </Tab>

        <Tab eventKey="link" label="Link">
          <TabContent>
            <HelpText>
              Share the link on your promotional channels & materials (e.g.
              website, social media, flyers etc).
            </HelpText>

            <Revealer isOpen={showInviteByLink}>
              <InviteByLink inviteUrl={inviteUrl} />
            </Revealer>
          </TabContent>
        </Tab>

        <Tab eventKey="resources" label="Resources">
          <TabContent>
            <HelpText>
              Do you offer composting equipment (e.g. compost bins, kitchen
              countertop containers) or education (e.g. workshops, materials)?
            </HelpText>
            <HelpText>
              If so, you can give these resources on the condition that people
              first register on MakeSoil. This makes people more likely to make
              use of the resources they receive, allows usage and participation
              to be tracked, and inspires more people to join the movement.
            </HelpText>
          </TabContent>
        </Tab>

        <Tab eventKey="create-soil-site" label="Soil Sites">
          <TabContent>
            <HelpText>
              Lead by example by hosting and managing your own drop-off site(s).
            </HelpText>

            <Flex justify="center">
              <Button onClick={() => history.push('/create-soil-site')}>
                CREATE A SOIL SITE
              </Button>
            </Flex>
          </TabContent>
        </Tab>
      </StyledTabs>
    </Modal>
  );
};

const StyledTabs = styled(Tabs)`
  span.label {
    font-size: 0.875rem;
    font-weight: 500;
  }
`;

const TabContent = styled.div`
  padding: 1rem;
`;

const Header = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;

  margin-bottom: 0.5rem;

  h3 {
    margin: 0;
  }
`;

const HelpText = styled.p`
  margin-top: 0.25rem;
  font-size: 0.95rem;
  color: rgb(79, 72, 72);
`;
