import { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Flex, Button, DropMenu } from '../../../components/ui';
import { ParticipationFeature } from './ParticipationFeature';
import { ImpactFeature } from './ImpactFeature';
import { Icon } from '../insights/components/Components';
import { Meta } from '../../../components/Meta';
import { InquiryModal } from './InquiryModal';
import {
  ActivateModal,
  ActivateModalTab,
} from '../insights/components/ActivateModal';
import { ShadowBox } from '../../../components/ui/Box';

export default function ActivateProductPage() {
  const history = useHistory();
  const [inquiryModalOpen, setInquiryModalOpen] = useState(false);

  const householdsParticipating = 1407;
  const activeSoilSites = Math.floor(householdsParticipating / 8);
  const foodWasteInPounds = 10400000;
  const numberOfDataPoints = 6;

  const [modalTab, setModalTab] = useState<ActivateModalTab>();

  const handleActivate = (tab: ActivateModalTab) => {
    setModalTab(tab);
  };

  return (
    <>
      <Meta>
        <title>Activate | MakeSoil</title>
      </Meta>

      {modalTab && (
        <ActivateModal
          tab={modalTab}
          isOpen={!!modalTab}
          onClose={() => setModalTab(null)}
        />
      )}

      <InquiryModal
        isOpen={inquiryModalOpen}
        onClose={() => setInquiryModalOpen(false)}
      />

      <PageContainer>
        <PageHeader>
          <Flex align="center" gap="0.5rem">
            <Icon>
              <FontAwesomeIcon icon="chart-line" />
            </Icon>
            <strong style={{ fontSize: '1.125rem' }}>ACTIVATE</strong>
          </Flex>
          <Flex>
            <DropMenu
              triggerElement={
                <Button padding="0.5rem 0.75rem">
                  <FontAwesomeIcon icon="user-plus" /> Activate
                </Button>
              }
            >
              <span onClick={() => handleActivate('qr')}>
                <FontAwesomeIcon icon="qrcode" /> Use the QR code
              </span>
              <span onClick={() => handleActivate('link')}>
                <FontAwesomeIcon icon="share" /> Share the link
              </span>
              <span onClick={() => handleActivate('resources')}>
                <FontAwesomeIcon icon="trash" /> Offer resources
              </span>
              <span onClick={() => history.push('/create-soil-site')}>
                <FontAwesomeIcon icon="leaf" /> Create a soil site
              </span>
            </DropMenu>
          </Flex>
        </PageHeader>

        <Hero>
          <Marketing direction="column" align="center" justify="center">
            <h1>
              See the current impact in your area, and get more of your
              community composting together!
            </h1>

            <Button
              onClick={() => setInquiryModalOpen(true)}
              variant="secondary"
            >
              UNLOCK YOUR IMPACT
            </Button>
          </Marketing>
        </Hero>

        <Features>
          <h2
            style={{
              marginTop: 0,
              marginBottom: '3rem',
              textAlign: 'center',
              fontWeight: 'lighter',
              color: '#818181',
            }}
          >
            Here is a sample of the kind of impact data you can unlock for your
            area
          </h2>

          <ShadowBox>
            <ParticipationFeature
              activeSoilSites={activeSoilSites}
              householdsParticipating={householdsParticipating}
              numberOfDataPoints={numberOfDataPoints}
            />
          </ShadowBox>

          <Spacer />

          <ShadowBox>
            <ImpactFeature
              foodScrapsInPounds={foodWasteInPounds}
              householdsParticipating={householdsParticipating}
            />
          </ShadowBox>
        </Features>

        {/* <Testimonials /> */}

        <Hero>
          <Marketing direction="column" align="center" justify="center">
            <Button
              onClick={() => setInquiryModalOpen(true)}
              variant="secondary"
            >
              UNLOCK YOUR IMPACT
            </Button>
          </Marketing>
        </Hero>
      </PageContainer>
    </>
  );
}

const Spacer = styled.div<{ height?: string }>`
  height: ${({ height }) => (height ? height : '2rem')};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: ${({ height }) => (height ? height : '5rem')};
  }
`;

const PageContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const PageHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  height: fit-content;
  background: #fff;
  border-bottom: 1px solid #dfdfdf;
  padding: 0.75rem 1rem;
`;

const Hero = styled.section`
  padding: 2rem 1rem;
  background: url('/images/map.png') center no-repeat;
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.primary};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 5rem;
  }
`;

const Marketing = styled(Flex)`
  text-align: center;
  text-wrap: balance;

  h1 {
    font-size: 2rem;
    font-weight: lighter;
    margin-bottom: 1.5rem;

    margin-top: 0;
  }

  color: #fff;
`;

const Features = styled.div`
  margin: 0 auto;
  padding: 5rem 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 85%;
  }
`;

const Region = styled.div`
  width: max-content;
  padding: 0.275rem 0.5rem;
  border: 1px solid #ebebeb;
  border-radius: 4px;

  background-color: #f9f9f9;

  &:hover {
    cursor: pointer;
    background-color: #f3f3f3;
  }

  transition: background-color 0.25s ease-in-out;
`;
