import { theme } from '../../../../app/theme';
import { Grid } from '../../../../components/ui';
import { formatWeight, formatCurrency } from '../../../../utils/formatting';
import { impactCalculator } from '../../../impact/impactCalculator';
import { InsightBox } from '../components/InsightBox';

type ImpactType = 'accumulated' | 'current-rate';
type ImpactBase = 'membership' | 'dropOff';

export interface ImpactProps {
  impactType: ImpactType;

  impactBase: ImpactBase;
  foodWasteInPounds: number;
  dropOffs: any[];
  householdsParticipating: number;
}

export function ImpactGrid({
  impactType,
  impactBase,
  householdsParticipating,
  foodWasteInPounds,
  dropOffs,
}: ImpactProps) {
  function getImpact() {
    if (impactType === 'current-rate') {
      return impactCalculator.impactRateYearly(householdsParticipating);
    }

    if (impactBase === 'dropOff') {
      return impactCalculator.impactByDropOffs(dropOffs);
    } else {
      return impactCalculator.impactByWeight(foodWasteInPounds);
    }
  }

  const { foodWasteSaved, ghg, soilCreated, feesAvoided } = getImpact();

  const suffix = impactType === 'current-rate' ? '/year' : '';

  return (
    <Grid col="repeat(2, 1fr)" gap="1rem">
      <InsightBox
        title="Scraps saved from landfill"
        value={`${formatWeight(foodWasteSaved)}${suffix}`}
        icon="apple-alt"
        color={theme.colors.primary}
        tooltipText="This is calculated based on the number of unique households registered at a soil site, and the average weight of US household food waste per year that can be composted (according to ReFED)."
      />
      <InsightBox
        title="GHG emissions prevented"
        value={`${formatWeight(ghg)}${suffix}`}
        icon="smog"
        color="#2f6fb1"
        tooltipText="This is calculated from the US EPA’s WARM model."
      />
      <InsightBox
        title="New soil created"
        value={`${formatWeight(soilCreated)}${suffix}`}
        icon="seedling"
        color="#82671F"
        tooltipText="This is calculated based on averages derived from real user data around the world."
      />
      <InsightBox
        title="Landfill fees avoided"
        value={`${formatCurrency(feesAvoided)}${suffix}`}
        icon="dollar"
        color="#616161"
        tooltipText="This is calculated based on recent average US landfill fees (according to EREF)."
      />
    </Grid>
  );
}
