import { ErrorMessage, useFormikContext } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormInputError } from '..';
import { credentials } from '../../../utils/config';
import { forwardRef } from 'react';

type RecaptchaProps = {
  name?: string;
  theme?: 'light' | 'dark';
  invisible?: boolean;
  style?: React.CSSProperties;
  onChange?: (token: string) => void;
};

export const RecaptchaFormControl = forwardRef<any, RecaptchaProps>(
  (
    {
      name = 'recaptcha',
      theme,
      invisible = false,
      style,
      onChange = null,
    }: RecaptchaProps,
    ref
  ) => {
    const { setFieldValue, setFieldError } = useFormikContext<{
      recaptcha: string;
    }>();

    return (
      <div style={style}>
        <ReCAPTCHA
          ref={ref}
          theme={theme}
          size={invisible ? 'invisible' : null}
          sitekey={credentials.get('RECAPTCHA_SITE_KEY')}
          onChange={(token: string) => {
            setFieldValue(name, token, true);
            onChange && onChange(token);
          }}
          onExpired={() => {
            setFieldError(name, 'Please complete the reCAPTCHA.');
          }}
        />
        <input type="hidden" name={name} />
        <ErrorMessage name={name} component={FormInputError} />
      </div>
    );
  }
);
