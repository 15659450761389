import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { Tabs } from '../../components/ui';
import { Tab } from '../../components/ui/Tabs';
import { isMobile } from '../../utils/helpers';
import { getQueryParameter } from '../../utils/url';
import { useCurrentSiteUser, useSoilSiteContext } from './hooks';

type Props = {
  siteInfoEditComponent: React.ReactNode;
  messageBoardComponent: React.ReactNode;
  participationComponent: React.ReactNode;
  siteImpactComponent: React.ReactNode;
};

export const TabLayout = ({
  siteInfoEditComponent,
  messageBoardComponent,
  participationComponent,
  siteImpactComponent,
}: Props) => {
  const history = useHistory();
  const location = useLocation();

  const { soilSite } = useSoilSiteContext();
  const { iAmSoilMaker } = useCurrentSiteUser(soilSite);

  const onTabSelected = (tab: string) => {
    history.push(`${location.pathname}?tab=${tab}`);
  };

  const onSwipeBack = () => {
    history.push('/my-soil-sites');
  };

  const showAccessRequestsIndicator =
    iAmSoilMaker && soilSite.accessRequests.length > 0;

  return (
    <StyledTabs
      selectedTab={getTabFromUrlParam(location.search) || 'impact'}
      onTabSelected={onTabSelected}
      swipeable={false}
      onSwipeBack={onSwipeBack}
      placement={isMobile() ? 'bottom' : 'top'}
    >
      <Tab
        eventKey="info"
        label="INFO"
        icon={<FontAwesomeIcon icon="info-circle" />}
      >
        {siteInfoEditComponent}
      </Tab>
      <Tab
        eventKey="impact"
        label="IMPACT"
        icon={<FontAwesomeIcon icon="trophy" />}
      >
        <div style={{ padding: '1rem' }}>{siteImpactComponent}</div>
      </Tab>
      <Tab
        eventKey="activity"
        label="ACTIVITY"
        icon={<FontAwesomeIcon icon="comments" />}
      >
        {messageBoardComponent}
      </Tab>
      <Tab
        eventKey="participants"
        label="PARTICIPANTS"
        indicator={
          showAccessRequestsIndicator && soilSite.accessRequests.length
        }
        icon={<FontAwesomeIcon icon="users" />}
      >
        {participationComponent}
      </Tab>
    </StyledTabs>
  );
};

/** Account for tab name change from messageboard to acitivity */
function getTabFromUrlParam(queryString: string) {
  const tab = getQueryParameter(queryString, 'tab');
  if (['activity', 'messageboard', 'messageBoard'].includes(tab)) {
    return 'activity';
  }
  return tab;
}

const StyledTabs = styled(Tabs)`
  ul.top {
    border-bottom: 1px solid #dbdbdb;
  }

  ul.bottom {
    border-top: 1px solid #cbcbcb;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    z-index: 1;

    li {
      padding-bottom: var(--safe-area-bottom, 0.5rem);
    }
  }

  li.active {
    border-bottom-color: ${({ theme }) => theme.colors.primary} !important;
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
  }
  li: not(.active) {
    border-bottom-color: transparent !important;
  }
`;
