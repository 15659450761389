import { createContext, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

type SwitchValue = string | number | readonly string[];

const SwitchContext = createContext(undefined);

type SwitchProps<SwitchValue> = {
  children: any[];
  defaultValue?: SwitchValue;
  onSelected?: (value: SwitchValue) => void;
  className?: string;
  style?: React.CSSProperties;
};

export default function Switch<SwitchValue>({
  children,
  defaultValue,
  onSelected,
  className,
  style,
}: SwitchProps<SwitchValue>) {
  const [currentValue, setCurrentValue] = useState<SwitchValue>(
    defaultValue || children[0].props.value
  );

  useEffect(() => {
    onSelected && onSelected(currentValue);
  }, [currentValue]);

  return (
    <SwitchContext.Provider value={{ currentValue, setCurrentValue }}>
      <SwitchContainer className={className} style={style}>
        {children.map((child) => {
          return (
            <Switch.SwitchButton<SwitchValue>
              key={child.props.value}
              value={child.props.value}
            >
              {child.props.children}
            </Switch.SwitchButton>
          );
        })}
      </SwitchContainer>
    </SwitchContext.Provider>
  );
}

type SwitchButtonProps = React.ComponentProps<'label'> & {
  value: SwitchValue;
};

function SwitchButton<SwitchValue>({ children, value }: SwitchButtonProps) {
  const { currentValue, setCurrentValue } = useContext(SwitchContext);

  return (
    <StyledSwitchButton htmlFor={value.toString()}>
      <input
        type="radio"
        id={value.toString()}
        value={value}
        checked={currentValue === value}
        onChange={() => setCurrentValue(value)}
      />

      {children}
    </StyledSwitchButton>
  );
}

Switch.SwitchButton = SwitchButton;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSwitchButton = styled.label<{ activeColor?: string }>`
  display: flex;
  gap: 0.25rem;
  align-items: center;

  padding: 0.5rem 0.75rem;

  background-color: #f3f3f3;
  border: 1px solid #d7d7d7;
  border-radius: 4px;

  font-size: 0.75rem;
  text-transform: uppercase;

  :has(input[type='radio']:checked) {
    background-color: ${({ theme, activeColor }) =>
      activeColor || theme.colors.primary};
    color: #fff;
    box-shadow: none;
  }

  input[type='radio'] {
    display: none;
  }

  :hover {
    cursor: pointer;
    background-color: #ebebeb;
  }

  :first-child {
    border-radius: 4px 0 0 4px;
  }

  :last-child {
    border-radius: 0 4px 4px 0;
    border-left: 0;
  }

  transition: all 0.25s ease;
`;
