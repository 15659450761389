import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import Chart from 'react-apexcharts';
import { theme } from '../../../app/theme';
import { Flex, Grid, Revealer } from '../../../components/ui';
import { Icon } from '../insights/components/Components';
import { InsightBox } from '../insights/components/InsightBox';
import { useMemo, useState } from 'react';
import { ToggleSwitch } from '../../../components/ui/ToggleSwitch';

export function ParticipationFeature({
  activeSoilSites,
  householdsParticipating,
  numberOfDataPoints,
}) {
  return (
    <>
      <Container>
        <Header>
          <Flex align="center" gap="0.5rem">
            <Icon>
              <FontAwesomeIcon icon="users" />
            </Icon>
            <strong>PARTICIPATION</strong>
          </Flex>
        </Header>
        <Content>
          <Grid col="repeat(2, 1fr)" gap="1rem">
            <InsightBox
              title="Soil Sites Activated"
              value={activeSoilSites}
              icon="leaf"
              color={theme.colors.primary}
            />
            <InsightBox
              title="Households Participating"
              value={householdsParticipating}
              icon="home"
              color={theme.colors.primary}
            />
          </Grid>
        </Content>

        <MapAndGraph
          householdsParticipating={householdsParticipating}
          numberOfDataPoints={numberOfDataPoints}
        />
      </Container>
    </>
  );
}

export function MapAndGraph({ householdsParticipating, numberOfDataPoints }) {
  const [showMapAndGraph, setShowMapAndGraph] = useState(true);
  const [view, setView] = useState<'grid' | 'list'>('grid');

  const dataPoints = useMemo(() => {
    return [200, 320, 527, 700, 954, householdsParticipating];

    // return randomIntArrayInRange(
    //   0,
    //   householdsParticipating,
    //   numberOfDataPoints
    // );
  }, [householdsParticipating, numberOfDataPoints]);

  return (
    <MapAndGraphContainer>
      <ControlPanel>
        <ToggleSwitchContainer>
          <ToggleSwitch
            label="SHOW MAP & GRAPH"
            on={showMapAndGraph}
            onToggle={() => setShowMapAndGraph((open) => !open)}
          />
        </ToggleSwitchContainer>
      </ControlPanel>

      <Revealer isOpen={showMapAndGraph}>
        <Content style={{ padding: '0 1rem 1rem' }}>
          <Grid view={view} gap="1rem">
            <Container>
              <FeatureContent style={{ padding: 0 }}>
                <img
                  src="/images/ms_world_map.webp"
                  alt="map of the world with soil sites markers"
                />
              </FeatureContent>
            </Container>

            <Container>
              <FeatureContent>
                <Chart
                  options={{
                    chart: {
                      id: 'participation-timeline',
                      type: 'line',
                      toolbar: {
                        show: false,
                      },
                    },
                    colors: [theme.colors.primary],
                    dataLabels: {
                      enabled: true,
                    },
                    stroke: {
                      curve: 'smooth',
                    },
                    grid: {
                      borderColor: '#e7e7e7',
                      row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5,
                      },
                    },
                    markers: {
                      size: 1,
                    },
                    xaxis: {
                      categories: generateMonthNames(numberOfDataPoints),
                    },
                    yaxis: {},
                  }}
                  series={[
                    {
                      name: 'Households Participating',
                      data: dataPoints,
                    },
                  ]}
                  type="line"
                  height="100%"
                />
              </FeatureContent>
            </Container>
          </Grid>
        </Content>
      </Revealer>
    </MapAndGraphContainer>
  );
}

function generateMonthNames(months = 6) {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const today = new Date();
  let date: Date;

  const result: string[] = [];

  for (let i = months; i >= 0; i -= 1) {
    result.push(
      monthNames[
        new Date(today.getFullYear(), today.getMonth() - i, 1).getMonth()
      ]
    );
  }
  return result;
}

function randomIntArrayInRange(min: number, max: number, n = 1) {
  const array = Array.from(
    { length: n - 1 },
    () => Math.floor(Math.random() * (max - min + 1)) + min
  );

  array.push(max);

  return array.sort((a, b) => a - b);
}

const FeatureContent = styled.div`
  padding: 0 1rem 1rem;

  height: 275px;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 350px;
  }

  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const MapAndGraphContainer = styled.div`
  background-color: #fafafacf;
  border-radius: 0 0 4px 4px;
`;

const ControlPanel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.35rem;

  padding: 0.75rem 1rem;
  font-size: 0.75rem;
  border-top: 1px solid #d7d7d7;
  border-radius: 0 0 4px 4px;
`;

const ToggleSwitchContainer = styled.div`
  background-color: #f8f8f8;
  padding: 0.25rem 0.25rem 0.25rem 0.75rem;
  font-size: 0.75rem;

  border: 1px solid #d7d7d7;
  border-radius: 4px 16px 16px 4px;
`;

const Content = styled.div`
  padding: 1rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;

  padding: 0.75rem 1rem;

  border-bottom: 1px solid #d7d7d7;
`;

const Container = styled.div`
  background-color: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
`;

const Region = styled.div`
  width: max-content;
  padding: 0.275rem 0.5rem;
  border: 1px solid #ebebeb;
  border-radius: 4px;

  background-color: #f9f9f9;

  &:hover {
    cursor: pointer;
    background-color: #f3f3f3;
  }

  transition: background-color 0.25s ease-in-out;
`;
