import { gql } from '@apollo/client';

export const GET_PREMIUM_FEATURES = gql`
  query PremiumFeatures($userId: Int!) {
    premiumFeatures(userId: $userId) {
      id
      name
      displayName
      enabled
      enabledAt
    }
  }
`;

export const GET_ASSIGNED_REGIONS = gql`
  query GetAssignedRegions($userId: Int!) {
    getAssignedRegions(userId: $userId) {
      id
      name
      type
      assignedAt
      userId
    }
  }
`;

export const ASSIGN_REGION = gql`
  mutation AssignRegion($input: AssignRegionInput!) {
    assignRegion(input: $input) {
      success
      region {
        id
        name
        type
        assignedAt
      }
    }
  }
`;

export const REMOVE_REGION = gql`
  mutation RemoveRegion($input: RemoveRegionInput!) {
    removeRegion(input: $input)
  }
`;

export const ENABLE_FEATURE = gql`
  mutation EnableFeature($input: PremiumFeatureInput!) {
    enableFeature(input: $input) {
      enabled
      enabledAt
    }
  }
`;

export const DISABLE_FEATURE = gql`
  mutation DisableFeature($input: PremiumFeatureInput!) {
    disableFeature(input: $input)
  }
`;
