import styled from 'styled-components';

export const ToggleSwitch = ({ on, label, onToggle, radius = 16 }) => {
  return (
    <SwitchContainer onClick={() => onToggle(!on)}>
      {label}
      <Switch>
        <input type="checkbox" onChange={onToggle} checked={on} />
        <Toggle radius={radius} />
      </Switch>
    </SwitchContainer>
  );
};

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  :hover {
    cursor: pointer;
  }
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + span {
      background-color: ${({ theme }) => theme.colors.primary};
    }

    &:checked + span:before {
      -webkit-transform: translateX(18px);
      -ms-transform: translateX(18px);
      transform: translateX(18px);
    }
  }
`;

const Toggle = styled.span<{ radius?: number }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;

  border-radius: ${({ radius }) => (radius ? radius + 'px' : '16px')};

  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.5s;

    border-radius: ${({ radius }) => (radius ? radius + 'px' : '16px')};
  }
`;
