import { useCallback } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { mapStyles } from '../../../map/map.styles';
import { ILocation, ViewportBounds } from '../../../../app/model/ILocation';
import styled from 'styled-components';

type Props = {
  location: ILocation;
  viewport: ViewportBounds;
  soilSites: { id: number; location: ILocation }[];
  onMapLoaded?: () => void;
};

export function ParticipationMap({
  location,
  viewport,
  soilSites,
  onMapLoaded,
}: Props) {
  const onLoad = useCallback(
    (map: google.maps.Map) => {
      if (location) {
        map.setCenter(location);
      }

      if (viewport) {
        const bounds = {
          south: viewport.south,
          west: viewport.west,
          north: viewport.north,
          east: viewport.east,
        };

        map.fitBounds(bounds);
        map.setOptions({
          restriction: {
            latLngBounds: bounds,
            strictBounds: false,
          },
        });
      }

      onMapLoaded && onMapLoaded();
    },
    [location.lat]
  );

  const onUnmount = useCallback((map: google.maps.Map) => {
    //map
  }, []);

  const styles = [...mapStyles];

  return (
    <Container>
      {/* <Header>
        <Icon>
          <FontAwesomeIcon icon="leaf" />
        </Icon>
        <strong>SOIL SITES ACTIVATED</strong>
      </Header> */}
      <Content>
        <GoogleMap
          key={location.lat}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          zoom={6}
          onLoad={onLoad}
          onUnmount={onUnmount}
          center={location}
          options={{
            gestureHandling: 'cooperative',
            scrollwheel: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            maxZoom: 15,
            styles,
          }}
        >
          {soilSites.map((marker) => (
            <Marker
              position={{ lat: marker.location.lat, lng: marker.location.lng }}
              key={marker.id}
              icon="/icons/soil-site-icon-exact.svg"
            />
          ))}
        </GoogleMap>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  border: 1px solid #d7d7d7;
  border-radius: 4px;

  transition all 0.25s ease;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  padding: 0.75rem 1rem;

  border-bottom: 1px solid #d7d7d7;

  h4 {
    margin: 0;
  }
`;

const Content = styled.div<{ height?: string }>`
  padding: 0.001rem;

  height: 275px;

  @media(min-width: ${({ theme }) => theme.breakpoints.sm}){
    height: 375px;
  }

  transition all 0.25s ease;
`;
